import Vue from "vue";
import router from "./router";
import store from "./store";

// Buefy + Icons
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";

import {
  faSave,
  faCalendarAlt,
  faArrowUp,
  faExclamationCircle,
  faExclamationTriangle,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faChevronRight,
  faEye,
  faList,
  faInfoCircle,
  faStar,
  faEnvelope,
  faCog,
  faCogs,
  faCaretDown,
  faCaretUp,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faClock,
  faDownload,
  faChalkboardTeacher,
  faGraduationCap,
  faPrint,
  faHeart,
  faCheck,
  faChartPie,
  faTable,
  faBusAlt,
  faBullhorn,
  faComments,
  faLightbulb,
  faMoneyBillAlt,
  faBolt,
  faEdit
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faGoogle
} from "@fortawesome/free-brands-svg-icons";

// import {
//   faPrint
// } from "@fortawesome/free-regular-svg-icons";

library.add(
  faSave,
  faCalendarAlt,
  faArrowUp,
  faExclamationCircle,
  faExclamationTriangle,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faChevronRight,
  faEye,
  faList,
  faInfoCircle,
  faStar,
  faEnvelope,
  faCog,
  faCogs,
  faCaretDown,
  faCaretUp,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faClock,
  faDownload,
  faChalkboardTeacher,
  faGraduationCap,
  faFacebook,
  faInstagram,
  faLinkedin,
  faGoogle,
  faPrint,
  faHeart,
  faCheck,
  faChartPie,
  faTable,
  faBusAlt,
  faBullhorn,
  faComments,
  faLightbulb,
  faMoneyBillAlt,
  faBolt,
  faEdit
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas"
});

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

import App from "./App.vue";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
