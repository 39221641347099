<template>
  <section>
    <b-button
      @click="$parent.$parent.updateSentModalActive = false"
      class="closeModal"
    >
      <b-icon icon="times" size="is-small"></b-icon>
    </b-button>
    <div class="header">ATUALIZAÇÃO ENVIADA</div>
    <div class="update">
      <br />
      <span>Sua solicitação foi enviada para a Empower!</span>
      <br /> <br />
      <span
        >Em até 2 dias úteis o status da ação será atualizado no sistema
        <br />
        e a Org. Estudantil/Professor(a) será informada(o)! :)</span
      >
    </div>
    <b-button class="ok-button" @click="$parent.$parent.updateSentModalActive = false">Ok</b-button>
  </section>
</template>

<script>
export default {
  name: "UpdateSent"
};
</script>

<style scoped>
section {
  background-color: #ffffff;
  padding: 2rem;
  padding-top: 0rem;
}
.closeModal {
  border: none;
  display: block;
  margin-left: auto;
  margin-right: -0.2rem;
  margin-top: 0;
}

.closeModal:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
}

.header {
  padding-bottom: 1rem;
  border-bottom: solid 1px #dbdbdb;
  font-weight: bold;
}

.update{
  text-align: center;
}

.ok-button{
  color: white;
  background-color: #363636;
  border-radius: 30px;
  width: 203px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  display: block;
}

.ok-button:hover{
  color: white;
  font-weight: 700;
}
</style>