<template>
  <section>
    <div class="main-logo">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="500.000000pt"
        height="71.000000pt"
        viewBox="0 0 2000.000000 285.000000"
        preserveAspectRatio="xMidYMid meet"
        class="bounced"
      >
        <g
          transform="translate(0.000000,285.000000) scale(0.100000,-0.100000)"
          fill="none"
          fill-rule="evenodd"
          stroke="#02fbc7"
          stroke-width="50"
        >
          <path
            d="M60 1430 l0 -1380 1175 0 1175 0 -2 277 -3 278 -895 2 -895 3 0 267
0 268 623 3 622 2 -2 278 -3 277 -620 2 -620 3 0 267 0 268 898 3 897 2 -2
278 -3 277 -1172 3 -1173 2 0 -1380z"
          />
          <path
            d="M2760 1430 l0 -1380 275 0 275 0 0 915 c0 503 3 915 8 915 4 0 203
-319 444 -708 240 -389 441 -707 447 -707 9 0 235 362 791 1264 l95 153 3
-916 2 -916 280 0 280 0 -2 1378 -3 1377 -321 3 -322 2 -174 -282 c-472 -766
-623 -1008 -629 -1008 -4 0 -177 276 -384 613 -208 336 -389 627 -402 645
l-24 32 -320 0 -319 0 0 -1380z"
          />
          <path
            d="M6010 1430 l0 -1380 275 0 275 0 0 300 0 300 398 0 c450 0 528 7 686
64 417 149 693 521 713 962 24 512 -308 964 -805 1094 -129 34 -267 40 -914
40 l-628 0 0 -1380z m1380 804 c100 -21 185 -68 259 -143 103 -103 151 -218
151 -361 0 -221 -128 -407 -339 -492 -55 -22 -66 -23 -478 -26 l-423 -3 0 514
c0 282 3 517 7 521 4 3 176 6 382 5 278 -1 393 -5 441 -15z"
          />
          <path
            d="M9923 2799 c-642 -57 -1158 -554 -1244 -1196 -15 -111 -7 -352 15
-453 56 -258 175 -483 354 -671 113 -119 231 -207 375 -279 320 -161 665 -194
1012 -95 121 34 331 140 436 220 263 197 447 486 521 816 30 137 33 417 5 548
-60 277 -174 495 -361 689 -243 251 -536 391 -886 422 -63 5 -119 9 -125 9 -5
-1 -51 -5 -102 -10z m322 -579 c153 -39 275 -111 386 -226 112 -117 174 -229
211 -383 27 -115 22 -310 -12 -416 -112 -353 -421 -585 -780 -586 -218 -1
-410 75 -566 224 -170 160 -254 356 -254 588 0 127 12 199 51 301 148 383 568
600 964 498z"
          />
          <path
            d="M11418 2805 c-6 -6 -10 7 344 -1097 134 -417 246 -758 249 -758 9 0
599 1842 593 1851 -6 10 -1177 13 -1186 4z"
          />
          <path
            d="M13186 2802 c-6 -9 585 -1852 594 -1852 8 0 599 1844 593 1852 -6 11
-1181 10 -1187 0z"
          />
          <path
            d="M14952 2798 c-5 -7 -207 -625 -447 -1373 -241 -748 -439 -1363 -442
-1367 -2 -5 725 -8 1616 -8 l1621 0 0 280 0 280 -887 0 c-489 0 -894 0 -901 0
-10 0 -12 57 -10 268 l3 267 623 3 622 2 0 280 0 280 -612 0 c-337 0 -619 0
-626 0 -10 0 -12 57 -10 268 l3 267 898 3 897 2 -2 278 -3 277 -1166 3 c-926
2 -1169 -1 -1177 -10z"
          />
          <path
            d="M17657 2803 c-4 -3 -7 -624 -7 -1380 l0 -1373 275 0 275 0 0 300 0
300 388 0 387 0 185 -300 185 -300 328 0 c309 0 327 1 318 17 -5 10 -113 185
-240 388 l-230 370 37 29 c20 17 82 75 137 131 114 115 179 216 231 355 57
150 68 216 68 395 -1 141 -4 172 -28 260 -118 443 -487 757 -946 805 -105 11
-1353 14 -1363 3z m1424 -581 c304 -101 444 -440 303 -731 -33 -68 -148 -185
-220 -223 -102 -55 -133 -58 -566 -58 l-398 0 0 521 0 520 408 -3 c388 -4 410
-5 473 -26z"
          />
          <path
            d="M12886 1889 c-3 -8 -88 -273 -190 -589 -102 -316 -232 -723 -290
-904 -58 -181 -106 -333 -106 -337 0 -5 268 -9 596 -9 l596 0 -7 28 c-15 63
-579 1808 -586 1816 -5 6 -10 3 -13 -5z"
          />
        </g>
      </svg>
    </div>
  </section>
</template>

<script>
import anime from "animejs";
export default {
  methods: {
    animeSvg() {
      anime({
        targets: ".main-logo path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 1000,
        delay: anime.stagger(100),
        direction: "alternate",
        loop: false,
      });
    },
  },
  mounted() {
    this.animeSvg();
  },
};
</script>

<style scoped>
section {
  height: 100%;
  background-color: black;
}
.main-logo {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.main-logo svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>