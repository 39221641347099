var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h2',[_vm._v("Pesquisa de Satisfação")]),_c('h3',[_vm._v("Realizada com os participantes das ações (avaliações de 1 a 5)")]),(_vm.survey)?_c('div',{staticClass:"survey-data"},[_c('div',{staticStyle:{"display":"flex"}},[(_vm.survey.totalRespondents)?_c('div',{staticClass:"number-of-respondents"},[_vm._v(" "+_vm._s(_vm.survey.totalRespondents)+" ")]):_vm._e(),_vm._v(" respondentes ")]),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"proportion"},[_c('span',{staticClass:"title-data"},[_vm._v("ANTES DAS AÇÕES")]),_c('span'),_c('span',[_vm._v("Conhecia a empresa?")]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"bar",style:('width: ' +
              (10 * _vm.survey.beforeAction.didYouKnowTheCompany) / 5 +
              'rem')}),_c('span',[_vm._v(_vm._s(_vm.survey.beforeAction.didYouKnowTheCompany))])]),_c('span',[_vm._v("Considerava trabalhar na empresa?")]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"bar",style:('width: ' +
              (10 *
                _vm.survey.beforeAction.wereYouConsideringWorkingAtTheCompany) /
                5 +
              'rem')}),_c('span',[_vm._v(_vm._s(_vm.survey.beforeAction.wereYouConsideringWorkingAtTheCompany))])])])]),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"proportion"},[_c('span',{staticClass:"title-data"},[_vm._v("APÓS AS AÇÕES")]),_c('span'),_c('span',[_vm._v("Considera trabalhar na empresa?")]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"bar",style:('width: ' +
              (10 * _vm.survey.afterAction.doYouConsiderWorkingAtTheCompany) / 5 +
              'rem')}),_c('span',[_vm._v(_vm._s(_vm.survey.afterAction.doYouConsiderWorkingAtTheCompany))])]),_c('span',[_vm._v("Indicaria a empresa para os seus amigos?")]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"bar",style:('width: ' +
              (10 * _vm.survey.afterAction.wouldYouReferTheCompanyToYouFriends) /
                5 +
              'rem')}),_c('span',[_vm._v(_vm._s(_vm.survey.afterAction.wouldYouReferTheCompanyToYouFriends))])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }