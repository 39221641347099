<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <b-carousel
          icon-size="is-large"
          :autoplay="true"
          :indicator="true"
          :indicator-background="false"
          :indicator-inside="false"
          progress-type="is-dark"
          indicatorMode="click"
          indicatorPosition="is-bottom"
          indicatorStyle="is-dots"
          :pauseInfo="false"
          :overlay="gallery"
        >
          <b-carousel-item
            v-for="(item, i) in carouselImages.length"
            :key="i"
            @click="gallery = !gallery"
          >
            <a class="image">
              <img
                :src="getImageUrlFromArray(i)"
                style="max-width: 1000px; margin: auto;"
              />
            </a>
          </b-carousel-item>

          <span
            v-if="gallery"
            class="modal-close is-large"
            style="position: relative; right: 4rem;"
            @click="gallery = !gallery"
          />

          <!-- <template #indicators="props">
            <figure class="al image" :draggable="false">
              <img :draggable="false" :src="getImgUrl(props.i)" :title="props.i" />
            </figure>
          </template> -->
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["carouselImages"],

  data() {
    return {
      gallery: false
    };
  },

  methods: {
    getImageUrlFromArray(i) {
      return this.carouselImages[i];
    },

    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 116px;
}
.column {
  padding: 0;
}
p u {
  cursor: pointer;
}
/* .is-active .al img {
  border: 1px solid #fff;
  filter: grayscale(0%);
}
.al img {
  border: 1px solid transparent;
  filter: grayscale(100%);
} */
</style>
