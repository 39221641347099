<template>
  <section>
    <b-collapse class="card" animation="slide" :open="false">
      <template #trigger="props">
        <div class="card-header" role="button" :aria-expanded="props.open">
          <div class="card-header-title">
            <p>{{ moment(minute.date, "DD/MM/YYYY") }}: {{ minute.title }}</p>
            <h3>Participantes Empower:
              <span v-for="(participant, i) in minute.empowerParticipants" :key="participant._id">
                {{ participant.name}}<span v-if="minute.empowerParticipants[i+1]">, </span>
                <span v-else>.</span>
              </span>
            </h3>
            <h3>Participantes {{ companyName }}: 
              {{ minute.companyParticipants }}
            </h3>
          </div>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'angle-up' : 'angle-down'" size="is-large" />
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content" v-html="minute.minute"></div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import { momentMixin } from "../../mixins/moment";
export default {
  name: "MeetingMinute",
  mixins: [momentMixin],
  props: ["minute", "companyName"],
};
</script>

<style scoped>
.card{
  margin-bottom: 1rem;
}
.card-header-title{
  display: block;
  padding: 1rem;
}
.icon{
  color: black;
}
</style>