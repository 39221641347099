export const filter = {
  performedActions(requests) {
    var numbers = {
      performed_actions: 0,
      impacted_students: 0,
      socialMediaFollowers: 0
    }
    for (let i = 0; i < requests.length; i++) {
      if (requests[i].visibleStatus == "Realizada") {
        numbers.performed_actions++;
        if (requests[i].eventInfo.realAttendeeQty) {
          numbers.impacted_students += requests[i].eventInfo.realAttendeeQty;
        } else {
          numbers.impacted_students += requests[i].eventInfo.attendeeQty || 0;
        }
        numbers.socialMediaFollowers += requests[i].organizationSocialMediaFollowers || 0;
      }
    }
    return numbers
  },

  distribuitionByType(requests) {
    var requests_performed = requests.filter((request) => {
      return request.visibleStatus == "Realizada"
    })
    var data = {
      quantity: requests_performed.length,
      number_of_students: 0,
      actions: [
        {
          name: 'Webinar',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Treinamento',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Visita Técnica',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Mentoria',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Palestra',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Projeto',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Patrocínio',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Outras Ações',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Participação em Aula',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Pesquisa',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Visita (Prof)',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Outras Ações (Prof)',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
      ]
    }
    for (let i = 0; i < requests_performed.length; i++) {
      if (requests_performed[i].eventInfo.attendeeQty) {
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'webinar') {
        data.actions[0].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[0].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[0].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'training') {
        data.actions[1].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[1].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[1].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'visit') {
        data.actions[2].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[2].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[2].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'mentoring') {
        data.actions[3].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[3].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[3].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'lecture') {
        data.actions[4].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[4].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[4].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'project') {
        data.actions[5].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[5].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[5].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'sponsorship') {
        data.actions[6].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[6].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[6].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'other') {
        data.actions[7].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[7].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[7].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'lesson') {
        data.actions[8].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[8].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[8].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'research') {
        data.actions[9].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[9].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[9].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'visitProf') {
        data.actions[10].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[10].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[10].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests_performed[i].requestType == 'otherProf') {
        data.actions[11].number++;
        if (requests_performed[i].eventInfo.realAttendeeQty) {
          data.actions[11].number_of_students += requests_performed[i].eventInfo.realAttendeeQty;
        }
        else {
          data.actions[11].number_of_students += requests_performed[i].eventInfo.attendeeQty || 0;
        }
      }
    }
    for (let i = 0; i < data.actions.length; i++) {
      data.actions[i].percentage = (data.actions[i].number * 100) / data.quantity;
      data.actions[i].percentage_of_students = (data.actions[i].number_of_students * 100) / data.number_of_students;
    }

    return data
  },

  latestActionsPerformed(requests) {
    var data = [];
    var requests_performed = requests.filter((request) => {
      return request.visibleStatus == "Realizada"
    })
    let date = '';
    let publicoReal = 0;
    for (let i = 0; i < requests_performed.length; i++) {
      if (requests_performed[i].eventInfo.dateOptions.agreed.start) {
        date = new Date(requests_performed[i].eventInfo.dateOptions.agreed.start)
      }
      if (requests_performed[i].eventInfo.realAttendeeQty){
        publicoReal = requests_performed[i].eventInfo.realAttendeeQty;
      } else {
        publicoReal = requests_performed[i].eventInfo.attendeeQty || 0;
      }

      try {
        data.push({
          data: date,
          tipo: requests_performed[i].requestType,
          uf: requests_performed[i].organization.state,
          universidade: requests_performed[i].organization.university,
          categoria: requests_performed[i].organization.category,
          oe_prof: requests_performed[i].organization.name,
          cursos: requests_performed[i].organization.membersPerCourse,
          publico: publicoReal,
          detalhes: null,
          request: requests_performed[i]
        })
      } catch {
        data.push({
          data: date,
          tipo: requests_performed[i].requestType,
          uf: requests_performed[i].professor.state,
          universidade: requests_performed[i].professor.university,
          categoria: "Professor",
          oe_prof: requests_performed[i].professor.name,
          cursos: requests_performed[i].eventInfo.subject.courses,
          publico: publicoReal,
          detalhes: null,
          request: requests_performed[i]
        })
      }
    }

    data.sort(function (a, b) {
      return b.data - a.data;
    })
    data.splice(5);
    return data;
  }
}