import Vue from "vue";
import Vuex from "vuex";

import request from "./modules/request";
import opportunity from "./modules/opportunity";
import report from "./modules/report";
import filters from "./modules/filters";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    request,
    opportunity,
    report,
    filters
  }
});
