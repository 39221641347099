<template>
  <section>
    <iframe
      width="560"
      height="415"
      :src="url+'?color=white'"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      autoplay="0"
    ></iframe>
  </section>
</template>

<script>
export default {
  name: "Webinar",
  props: ["url"],
};
</script>

<style scoped>
iframe{
  margin: auto;
  height: 30rem;
  width: 100%;
}
@media (max-width: 1450px) {
  iframe{
    height: 20rem;
  }
}
@media (max-width: 1200px) {
  iframe{
    height: 25rem;
  }
}
</style>