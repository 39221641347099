<template>
  <div class="map-chart">
    <svg
      ref="svg"
      :height="windowWidth > 1600 ? 420 : 400"
      :width="windowWidth > 1600 ? 400 : 310"
    >
      <path
        v-for="state in stateData"
        :key="state.feature.properties.sigla"
        :d="pathGenerator(state.feature)"
        :style="{
          fill: state.color,
        }"
      />
    </svg>
    <div>
      <div class="bar-by-state-content">
        <div class="bar-by-state">
          <div>
            <div v-for="state in rankStates" :key="state.state" class="state">
              {{ state.state }}
            </div>
          </div>
          <div>
            <div
              v-for="state in rankStates"
              :key="state.state"
              style="display: flex"
            >
              <div
                class="bar"
                :style="
                  'width:' +
                  state.percentage +
                  'px;' +
                  'background-color:' +
                  (state.percentage > 40
                    ? '#00B991'
                    : state.percentage > 30
                    ? '#33c7a7'
                    : state.percentage > 20
                    ? '#66d5bd'
                    : state.percentage > 10
                    ? '#99e3d3'
                    : state.percentage > 0
                    ? '#ccf1e9'
                    : '#ededed')
                "
              ></div>
              - {{ state.rank }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { filter } from "../requested-actions-filter/requested-actions-filter";
import { responsive } from "../../../../mixins/responsive";
export default {
  props: ["requests"],
  mixins: [responsive],
  data() {
    return {
      statesJson: null,
      featuredState: "",
    };
  },
  computed: {
    screenSize() {
      if (this.windowWidth > 1600) {
        return [480, -21];
      } else return [430, -11];
    },
    requestsStates() {
      if (this.requests) {
        return filter.distributionByState(this.requests);
      } else return null;
    },
    projection() {
      return d3
        .geoMercator()
        .scale(this.screenSize[0])
        .center([this.screenSize[1], -19]);
    },
    pathGenerator() {
      return d3.geoPath().projection(this.projection);
    },
    stateData() {
      return this.statesJson
        ? this.statesJson.features.map((feature) => {
            let state = this.classifyStates.find(
              (state) => state.state === feature.properties.sigla
            );
            return {
              feature,
              color: this.stateColor(state.rank),
            };
          })
        : [];
    },
    stateColor() {
      return d3
        .scaleOrdinal()
        .domain([1, 2, 3, 4, 5, 6])
        .range([
          "#ededed",
          "#ccf1e9",
          "#99e3d3",
          "#66d5bd",
          "#33c7a7",
          "#00b991",
        ]); //https://imagecolorpicker.com/color-code/02f8c7
    },
    classifyStates() {
      var classified_states = [];
      let biggestValue = this.orderedStates()[0].rank;
      if (this.requestsStates) {
        for (let i = 0; i < this.requestsStates.length; i++) {
          if (this.requestsStates[i].rank == 0) {
            classified_states.push({
              state: this.requestsStates[i].state,
              rank: 1,
            });
          } else if ((100 * this.requestsStates[i].rank) / biggestValue < 10) {
            classified_states.push({
              state: this.requestsStates[i].state,
              rank: 2,
            });
          } else if ((100 * this.requestsStates[i].rank) / biggestValue < 20) {
            classified_states.push({
              state: this.requestsStates[i].state,
              rank: 3,
            });
          } else if ((100 * this.requestsStates[i].rank) / biggestValue < 30) {
            classified_states.push({
              state: this.requestsStates[i].state,
              rank: 4,
            });
          } else if ((100 * this.requestsStates[i].rank) / biggestValue < 40) {
            classified_states.push({
              state: this.requestsStates[i].state,
              rank: 5,
            });
          } else if ((100 * this.requestsStates[i].rank) / biggestValue >= 40) {
            classified_states.push({
              state: this.requestsStates[i].state,
              rank: 6,
            });
          }
        }
        return classified_states;
      } else return null;
    },
    rankStates() {
      let list = this.orderedStates();
      let biggestValue = list[0].rank;
      let rankedStates = [];
      for (let i = 0; i < list.length; i++) {
        let percentage = (100 * list[i].rank) / biggestValue;
        if (list[i].rank > 0) {
          rankedStates.push({
            state: list[i].state,
            rank: list[i].rank,
            percentage: percentage,
          });
        }
      }
      if (rankedStates.length > 10) {
        rankedStates.splice(10);
        let other = 0;
        for (let i = 10; i < rankedStates.length; i++) {
          other += list[i].rank;
        }
        let otherPercentage = (100 * other) / biggestValue;
        rankedStates.push({
          state: "Outros",
          rank: other,
          percentage: otherPercentage,
        });
      }
      return rankedStates;
    },
  },
  methods: {
    orderedStates() {
      return this.requestsStates.sort(function (a, b) {
        if (a.rank < b.rank) {
          return 1;
        }
        if (a.rank > b.rank) {
          return -1;
        }
        return 0;
      });
    },
  },
  created() {
    this.statesJson = require("../../../../assets/js/brazil_geo.json");
    //https://www.jrossetto.com.br/tutoriais/jquery-javascript/arquivo-geojson-do-brasil-para-echarts/
  },
};
</script>

<style scoped>
.map-chart {
  display: grid;
  grid-template-columns: auto auto;
  max-width: 40rem;
}
.state {
  text-align: right;
  padding-right: 0.5rem;
  font-size: 1.05rem;
}
.bar-by-state-content {
  margin-top: 3.2rem;
}
.bar-by-state {
  display: grid;
  grid-template-columns: auto auto;
}
.bar {
  height: 1.35rem;
  margin-top: 0.2rem;
  margin-right: 0.3rem;
}
.featured-state {
  background-color: #02dfb3;
  color: white;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.2rem;
  text-align: center;
  margin-top: 2rem;
}

path {
  stroke: #fdfdfd;
  stroke-width: 1.5;
}
</style>