<template>
  <div id="info3" class="container">
    <div class="columns">
      <div class="column text-content">
        <div class="quill-editor">
          <div class="ql-container ql-bubble">
            <div class="ql-editor" v-html="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";

export default {
  props: ["content"]
};
</script>

<style scoped>
#info3 {
  margin-top: 116px;
}
.quill-editor {
  border: 1px solid rgb(211, 211, 211);
}
.text-content {
  padding: 0;
}
@media (max-width: 1023px) {
  .text-content {
    padding: 0 1.5rem 0 0.75rem;
  }
}
@media (max-width: 768px) {
  .text-content {
    padding: 0 1.5rem;
  }
}
.ql-editor {
  padding: 3rem;
}
</style>
