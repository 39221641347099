<template>
  <section>
    <h2>Pesquisa de Satisfação</h2>
    <h3>Realizada com os participantes das ações (avaliações de 1 a 5)</h3>
    <div class="survey-data" v-if="survey">
      <div style="display: flex">
        <div class="number-of-respondents" v-if="survey.totalRespondents">
          {{ survey.totalRespondents }}
        </div>
        respondentes
      </div>
      <div class="data-content">
        <div class="proportion">
          <span class="title-data">ANTES DAS AÇÕES</span>
          <span></span>
          <span>Conhecia a empresa?</span>
          <div style="display: flex">
            <!-- 10rem é 100% da barra -->
            <div
              class="bar"
              :style="
                'width: ' +
                (10 * survey.beforeAction.didYouKnowTheCompany) / 5 +
                'rem'
              "
            ></div>
            <span>{{ survey.beforeAction.didYouKnowTheCompany }}</span>
          </div>
          <span>Considerava trabalhar na empresa?</span>
          <div style="display: flex">
            <div
              class="bar"
              :style="
                'width: ' +
                (10 *
                  survey.beforeAction.wereYouConsideringWorkingAtTheCompany) /
                  5 +
                'rem'
              "
            ></div>
            <span>{{
              survey.beforeAction.wereYouConsideringWorkingAtTheCompany
            }}</span>
          </div>
        </div>
      </div>

      <div class="data-content">
        <div class="proportion">
          <span class="title-data">APÓS AS AÇÕES</span>
          <span></span>
          <span>Considera trabalhar na empresa?</span>
          <div style="display: flex">
            <!-- 10rem é 100% da barra -->
            <div
              class="bar"
              :style="
                'width: ' +
                (10 * survey.afterAction.doYouConsiderWorkingAtTheCompany) / 5 +
                'rem'
              "
            ></div>
            <span>{{
              survey.afterAction.doYouConsiderWorkingAtTheCompany
            }}</span>
          </div>
          <span>Indicaria a empresa para os seus amigos?</span>
          <div style="display: flex">
            <div
              class="bar"
              :style="
                'width: ' +
                (10 * survey.afterAction.wouldYouReferTheCompanyToYouFriends) /
                  5 +
                'rem'
              "
            ></div>
            <span>{{
              survey.afterAction.wouldYouReferTheCompanyToYouFriends
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SatisfactionSurvey",
  props: ["survey"],
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
}
h2 {
  font-size: 20px;
  color: #404040;
  font-weight: 700;
}
.survey-data {
  margin: 2rem;
}
.number-of-respondents {
  background-color: #667de9;
  width: 3rem;
  color: white;
  font-weight: bold;
  text-align: right;
  margin-right: 0.5rem;
  padding-right: 0.3rem;
}
.data-content {
  width: 31rem;
  margin: auto;
  margin-top: 2rem;
  text-align: center;
}
.title-data {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: -.8rem;
}
.proportion {
  display: grid;
  grid-template-columns: 18.5rem 12rem;
  gap: 1rem;
}
.proportion span {
  text-align: right;
}
.bar {
  background-color: #ffc92f;
  margin-right: 0.5rem;
  height: 1.5rem;
}
</style>