<template>
  <div>
    <table>
      <thead>
        <tr>
          <th colspan="2">
            <span class="table-head text-head">Tipo de Ação</span>
          </th>
          <th>
            <span class="text-head" style="text-align: center"
              >Solicitações</span
            >
          </th>
          <th>
            <span class="text-head" style="text-align: center">%</span>
          </th>
        </tr>
      </thead>
      <tr v-for="(type, i) in tableData" :key="i">
        <td>
          <b-icon :icon="icons[type.name]" size="is-small"></b-icon>
        </td>
        <td>{{ requestTypeText(type.name, 1) }}</td>
        <td>{{ type.quantity }}</td>
        <td v-if="type.percentage >= 10">{{ type.percentage.toFixed(1) }}%</td>
        <td v-else>0{{ type.percentage.toFixed(1) }}%</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { filter } from "../requested-actions-filter/requested-actions-filter";
import { request } from "../../../../mixins/request";
export default {
  name: "TypesTable",
  props: ["requests"],
  mixins: [request],
  data() {
    return {
      icons: {
        lecture: "chalkboard-teacher" ,
        visit: "bus-alt",
        webinar: "bullhorn",
        training: "cogs",
        mentoring: "comments",
        project: "lightbulb",
        sponsorship: "money-bill-alt",
        other: "bolt",
        lesson: "chalkboard-teacher",
        research: "lightbulb",
        visitProf: "bus-alt",
        otherProf: "bolt",
      },
    };
  },
  computed: {
    tableData() {
      if (this.requests) {
        return filter.distribuitionByType(this.requests);
      } else return null;
    }
  },
};
</script>

<style scoped>
table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.text-head {
  margin-top: 0.3rem;
  display: block;
}
tr {
  border-bottom: #f0f0f0 solid 1px;
  height: 2rem;
}
th {
  background-color: #ededed;
  height: 2rem;
}
td:nth-child(1) {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  width: 2.5rem;
}
td:nth-child(1) span {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
td:nth-child(2) {
  padding-top: 0.2rem;
}
td {
  width: 100%;
}
td:nth-child(3),
td:nth-child(4) {
  text-align: center;
}
th:nth-child(3) {
  width: 4rem;
  display: flow-root;
}

.table-head {
  margin-left: 1rem;
}
</style>