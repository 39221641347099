<template>
  <section>
    <h2>Distribuição por Estado</h2>
    <MapChart :requests="requests" />
  </section>
</template>

<script>
import MapChart from "./charts/MapChart"
export default {
  name: "DistributionByState",
  props: ["requests"],
  components: {
    MapChart
  }
}
</script>

<style scoped>
section{
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  height: 30rem;
}
h2{
  font-size: 20px;
  color: #404040;
  font-weight: 700;
}
</style>