const state = {
  showFilters: false,
  contract: null
};

const getters = {
  showFilters: state => {
    return state.showFilters;
  },
  contract: state => {
    return state.contract;
  }
};

const actions = {
  setFiltersVisibility({ commit }, filtersVisibility) {
    commit("SET_FILTERS_VISIBILITY", filtersVisibility);
  },
  setContract({ commit }, contract) {
    commit("SET_CONTRACT", contract);
  }
};

const mutations = {
  SET_FILTERS_VISIBILITY(state, filtersVisibility) {
    state.showFilters = filtersVisibility;
  },
  SET_CONTRACT(state, contract) {
    state.contract = contract;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
