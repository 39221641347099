<template>
  <section class="feedback-modal">
    <b-button
      @click="$parent.$parent.refuseActionModalActive = false"
      class="closeModal"
    >
      <b-icon icon="times" size="is-small"></b-icon>
    </b-button>
    <div class="header" v-if="request.organization">
      RECUSAR AÇÃO - {{ request.organization.name }}
    </div>
    <div class="header" v-else-if="request.professor">
      RECUSAR AÇÃO - Prof. {{ request.professor.name }}
    </div>
    <b-field label="Motivo da recusa da ação:">
      <b-select
        expanded
        placeholder="Selecione aqui um dos motivos para recusar a ação"
        v-model="noAvailabilityReason"
      >
        <option value="">
          Selecione aqui um dos motivos para recusar a ação
        </option>
        <option value="Diferente do perfil-alvo">
          Diferente do perfil-alvo
        </option>
        <option value="Tema muito demandado">Tema muito demandado</option>
        <option value="Gestor em férias ou licença">
          Gestor em férias ou licença
        </option>
        <option value="Gestor não retornou">Gestor não retornou</option>
        <option value="Tema não é de expertise">Tema não é de expertise</option>
        <option value="Outros">Outros</option>
      </b-select>
    </b-field>

    <b-field label="Comentários">
      <b-input
        type="textarea"
        placeholder="Se tiver algo a adicionar, escreva aqui!"
        v-model="noAvailabilityComment"
      >
      </b-input>
    </b-field>
    <div style="width: fit-content; margin-right: auto; margin-left: auto">
      <b-button
        class="feedback recusar"
        icon-left="times"
        rounded
        @click="denyRequest"
        >Recusar Ação</b-button
      >
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  nome: "RefuseAction",
  props: ["request"],
  data() {
    return {
      noAvailabilityReason: "",
      noAvailabilityComment: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    denyRequest() {
      if (this.validateInput().length > 0) {
        this.alertValidation(this.validateInput());
      } else {
        var data = {
          token: this.token,
          requestId: this.request._id,
          noAvailabilityReason: this.noAvailabilityReason,
          noAvailabilityComment: this.noAvailabilityComment,
        };

        this.$store
          .dispatch("denyRequest", data)
          .then((res) => {
            this.$parent.$parent.updateSentModalActive = true;
            this.$parent.$parent.refuseActionModalActive = false;
            return res;
          })
          .catch((e) => {
            this.$buefy.dialog.alert(
              "Um erro ocorreu, tente novamente mais tarde."
            );
            return e;
          });
      }
    },
    validateInput() {
      let pendencies = [];
      if (this.noAvailabilityReason === "") {
        pendencies.push("Motivo da recusa da ação");
      }
      return pendencies;
    },
    alertValidation(pendencies) {
      let pendenciesFormated = "";
      for (let i = 0; i < pendencies.length; i++) {
        pendenciesFormated += "<li>- " + pendencies[i] + "</li>";
      }
      this.$buefy.dialog.alert({
        title: "Entradas inválidas",
        message:
          "<b>Preencha as seguintes entradas corretamente:</b> <br> <ul>" +
          pendenciesFormated +
          "</ul>",
        type: "is-danger",
      });
    },
  },
};
</script>

<style scoped>
.closeModal {
  border: none;
  display: block;
  margin-left: auto;
  margin-right: -0.2rem;
  margin-top: 0;
}

.closeModal:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
}

.header {
  padding-bottom: 1rem;
  border-bottom: solid 1px #dbdbdb;
  margin-bottom: 1rem;
  font-weight: bold;
}

.recusar {
  background-color: #fe5469;
  color: white;
  font-weight: bold;
  margin-top: 2rem;
}
</style>