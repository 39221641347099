const commom = {
  methods: {
    changeFiltersVisibility() {
      this.$store.dispatch(
        "setFiltersVisibility",
        !this.$store.getters.showFilters
      );
    },

    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },

    alertError(data) {
      if (data.message === "EXPIRED_TOKEN") {
        this.alertExpiredToken();
      } else {
        this.alertCustomError(data.message);
      }
    },

    alertExpiredToken() {
      this.$buefy.dialog.alert({
        title: "Sua sessão expirou",
        message: "Faça login novamente",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        onConfirm: () => this.$router.push("/"),
        onCancel: () => this.$router.push("/")
      });
    },

    alertCustomError(err) {
      this.$buefy.dialog.alert({
        title: "Erro :(",
        message: this.translateError(err),
        type: "is-warning",
        hasIcon: true,
        icon: "exclamation-triangle",
        iconPack: "fa"
      });
    },

    translateError(message) {
      const translations = {
        INVALID_TOKEN:
          "Link expirado. Por favor, contate a Empower para obter a URL atualizada.",
        INVALID_REQUEST_ID: "ID de solicitação inválido",
        INVALID_STATUS: "Status inválido",
        INVALID_DENY_REASON: "Motivo de recusa inválido"
      };
      return translations[message] || "Por favor, tente novamente mais tarde..";
    }
  }
};

export { commom };
