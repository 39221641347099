export const filter = {
  confirmedActions(requests){
    var requests_confirmed = requests.filter((request) => {
      return request.visibleStatus == "Confirmada"
    })
    var data = {
      quantity: requests_confirmed.length,
      number_of_students: 0,
      actions: [
        {
          name: 'Webinar',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Treinamento',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Visita Técnica',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Mentoria',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Palestra',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Projeto',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Patrocínio',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Outras Ações',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Participação em Aula',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Pesquisa',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Visita (Prof)',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
        {
          name: 'Outras Ações (Prof)',
          number: 0,
          percentage: 0,
          number_of_students: 0,
          percentage_of_students: 0
        },
      ]
    }
    for(let i = 0; i<requests_confirmed.length; i++){
      if(requests_confirmed[i].eventInfo.attendeeQty){
        data.number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'webinar'){
        data.actions[0].number ++;
        data.actions[0].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'training'){
        data.actions[1].number ++;
        data.actions[1].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'visit'){
        data.actions[2].number ++;
        data.actions[2].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'mentoring'){
        data.actions[3].number ++;
        data.actions[3].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'lecture'){
        data.actions[4].number ++;
        data.actions[4].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'project'){
        data.actions[5].number ++;
        data.actions[5].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'sponsorship'){
        data.actions[6].number ++;
        data.actions[6].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'other'){
        data.actions[7].number ++;
        data.actions[7].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'lesson'){
        data.actions[8].number ++;
        data.actions[8].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'research'){
        data.actions[9].number ++;
        data.actions[9].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'visitProf'){
        data.actions[10].number ++;
        data.actions[10].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
      if(requests_confirmed[i].requestType == 'otherProf'){
        data.actions[11].number ++;
        data.actions[11].number_of_students += requests_confirmed[i].eventInfo.attendeeQty || 0;
      }
    }
    for(let i = 0; i<data.actions.length; i++){
      data.actions[i].percentage = ((data.actions[i].number * 100) / data.quantity) || 0;
      data.actions[i].percentage_of_students = ((data.actions[i].number_of_students * 100) / data.number_of_students) || 0;
    }

    return data
  },

  nextActions(requests){
    var data = [];
    var requests_confirmed = requests.filter((request) => {
      return request.visibleStatus == "Confirmada"
    })
    let date = '';
    for(let i = 0; i<requests_confirmed.length; i++){
      if(requests_confirmed[i].eventInfo.dateOptions.agreed.start){
        date = new Date(requests_confirmed[i].eventInfo.dateOptions.agreed.start)
      }
      try{
        data.push({
          data: date,
          tipo: requests_confirmed[i].requestType,
          uf: requests_confirmed[i].organization.state,
          universidade: requests_confirmed[i].organization.university,
          categoria: requests_confirmed[i].organization.category,
          oe_prof: requests_confirmed[i].organization.name,
          cursos: requests_confirmed[i].organization.membersPerCourse,
          publico: requests_confirmed[i].eventInfo.attendeeQty,
          detalhes: null,
          request: requests_confirmed[i]
        })
      } catch {
        data.push({
          data: date,
          tipo: requests_confirmed[i].requestType,
          uf: requests_confirmed[i].professor.state,
          universidade: requests_confirmed[i].professor.university,
          categoria: "Professor",
          oe_prof: requests_confirmed[i].professor.name,
          cursos: requests_confirmed[i].eventInfo.subject.courses,
          publico: requests_confirmed[i].eventInfo.attendeeQty,
          detalhes: null,
          request: requests_confirmed[i]
        })
      }
    }
    data.sort(function(a,b) {
      return a.data - b.data;
    })
    // data.splice(5);
    return data;
  }
}