<template>
  <section>
    <div>
      <div class="total-numbers">
        <data-box
          :number="requestData.number_of_requests"
          message="Ações sem disponibilidade"
          color="#747474"
        />
        <data-box
          :number="requestData.number_of_students"
          message="Estudantes que poderiam <br> ser impactados"
          color="#747474"
        />
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th><span>Motivo</span></th>
              <th style="text-align: center"><span>Ações</span></th>
              <th style="text-align: center"><span>Estudantes</span></th>
            </tr>
          </thead>
          <tr>
            <td class="status">
              <span>Diferente do Perfil-Alvo</span>
            </td>
            <td>
              <span v-if="requestData.diferente_perfil.quantity >= 10">{{
                requestData.diferente_perfil.quantity
              }}</span>
              <span v-else>0{{ requestData.diferente_perfil.quantity }}</span>
              <span v-if="requestData.diferente_perfil.percentage >= 10"
                >{{ requestData.diferente_perfil.percentage.toFixed(1) }}%</span
              >
              <span v-else
                >0{{
                  requestData.diferente_perfil.percentage.toFixed(1)
                }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.diferente_perfil.students >= 10">{{
                requestData.diferente_perfil.students
              }}</span>
              <span v-else>0{{ requestData.diferente_perfil.students }}</span>
              <span v-if="requestData.diferente_perfil.percentage_students >= 10"
                >{{
                  requestData.diferente_perfil.percentage_students.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.diferente_perfil.percentage_students.toFixed(1)
                }}%</span
              >
            </td>
          </tr>
          <tr>
            <td class="status">
              <span>Tema muito demandado</span>
            </td>
            <td>
              <span v-if="requestData.tema_demandado.quantity >= 10">{{
                requestData.tema_demandado.quantity
              }}</span>
              <span v-else>0{{ requestData.tema_demandado.quantity }}</span>
              <span v-if="requestData.tema_demandado.percentage >= 10"
                >{{ requestData.tema_demandado.percentage.toFixed(1) }}%</span
              >
              <span v-else
                >0{{ requestData.tema_demandado.percentage.toFixed(1) }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.tema_demandado.students >= 10">{{
                requestData.tema_demandado.students
              }}</span>
              <span v-else>0{{ requestData.tema_demandado.students }}</span>
              <span v-if="requestData.tema_demandado.percentage_students >= 10"
                >{{
                  requestData.tema_demandado.percentage_students.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.tema_demandado.percentage_students.toFixed(1)
                }}%</span
              >
            </td>
          </tr>
          <tr>
            <td class="status">
              <span>Gestor de Férias ou Licença</span>
            </td>
            <td>
              <span v-if="requestData.gestor_ferias.quantity >= 10">{{
                requestData.gestor_ferias.quantity
              }}</span>
              <span v-else>0{{ requestData.gestor_ferias.quantity }}</span>
              <span v-if="requestData.gestor_ferias.percentage >= 10"
                >{{ requestData.gestor_ferias.percentage.toFixed(1) }}%</span
              >
              <span v-else
                >0{{ requestData.gestor_ferias.percentage.toFixed(1) }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.gestor_ferias.students >= 10">{{
                requestData.gestor_ferias.students
              }}</span>
              <span v-else>0{{ requestData.gestor_ferias.students }}</span>
              <span v-if="requestData.gestor_ferias.percentage_students >= 10"
                >{{
                  requestData.gestor_ferias.percentage_students.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.gestor_ferias.percentage_students.toFixed(1)
                }}%</span
              >
            </td>
          </tr>
          <tr>
            <td class="status">
              <span>Gestor não retornou</span>
            </td>
            <td>
              <span v-if="requestData.gestor_nao_retornou.quantity >= 10">{{
                requestData.gestor_nao_retornou.quantity
              }}</span>
              <span v-else
                >0{{ requestData.gestor_nao_retornou.quantity }}</span
              >
              <span v-if="requestData.gestor_nao_retornou.percentage >= 10"
                >{{
                  requestData.gestor_nao_retornou.percentage.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.gestor_nao_retornou.percentage.toFixed(1)
                }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.gestor_nao_retornou.students >= 10">{{
                requestData.gestor_nao_retornou.students
              }}</span>
              <span v-else
                >0{{ requestData.gestor_nao_retornou.students }}</span
              >
              <span
                v-if="requestData.gestor_nao_retornou.percentage_students >= 10"
                >{{
                  requestData.gestor_nao_retornou.percentage_students.toFixed(
                    1
                  )
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.gestor_nao_retornou.percentage_students.toFixed(
                    1
                  )
                }}%</span
              >
            </td>
          </tr>
          <tr>
            <td class="status">
              <span>Tema não é de expertise</span>
            </td>
            <td>
              <span v-if="requestData.tema_expertise.quantity >= 10">{{
                requestData.tema_expertise.quantity
              }}</span>
              <span v-else>0{{ requestData.tema_expertise.quantity }}</span>
              <span v-if="requestData.tema_expertise.percentage >= 10"
                >{{ requestData.tema_expertise.percentage.toFixed(1) }}%</span
              >
              <span v-else
                >0{{ requestData.tema_expertise.percentage.toFixed(1) }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.tema_expertise.students >= 10">{{
                requestData.tema_expertise.students
              }}</span>
              <span v-else>0{{ requestData.tema_expertise.students }}</span>
              <span v-if="requestData.tema_expertise.percentage_students >= 10"
                >{{
                  requestData.tema_expertise.percentage_students.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.tema_expertise.percentage_students.toFixed(1)
                }}%</span
              >
            </td>
          </tr>
          <tr>
            <td class="status">
              <span>Outros</span>
            </td>
            <td>
              <span v-if="requestData.outros.quantity >= 10">{{
                requestData.outros.quantity
              }}</span>
              <span v-else>0{{ requestData.outros.quantity }}</span>
              <span v-if="requestData.outros.percentage >= 10"
                >{{ requestData.outros.percentage.toFixed(1) }}%</span
              >
              <span v-else
                >0{{ requestData.outros.percentage.toFixed(1) }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.outros.students >= 10">{{
                requestData.outros.students
              }}</span>
              <span v-else>0{{ requestData.outros.students }}</span>
              <span v-if="requestData.outros.percentage_students >= 10"
                >{{ requestData.outros.percentage_students.toFixed(1) }}%</span
              >
              <span v-else
                >0{{ requestData.outros.percentage_students.toFixed(1) }}%</span
              >
            </td>
          </tr>

          <tr>
            <th class="status">
              <span>Total</span>
            </th>
            <th class="secondary-head">
              <span>{{ requestData.number_of_requests }}</span>
              <span>100%</span>
            </th>
            <th class="secondary-head">
              <span>{{ requestData.number_of_students }}</span>
              <span>100%</span>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import DataBox from "../../commom/DataBox";
import { filter } from "./without-availability-actions-filter/without-availability-actions-filter";
export default {
  name: "GeneralRequests",
  components: {
    DataBox,
  },
  props: ["requests"],
  computed: {
    requestData() {
      if (this.requests) {
        return filter.generalRequests(this.requests);
      } else return null;
    },
  },
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem;
}
.total-numbers {
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  gap: 3%;
}
table {
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
}
th {
  background-color: #ededed;
  height: 2rem;
}
tr {
  border-bottom: #f0f0f0 solid 1px;
}
.status {
  display: flex;
}
span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
table td:not([align]) {
  text-align: center !important;
}
.secondary-head {
  text-align: center;
}
</style>