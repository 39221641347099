<template>
  <section class="report-panel">
    <div class="config">
      <h2>Configurações</h2>
      <b-button @click="generatePDF">Baixar Documento</b-button>
    </div>
    <div class="structure" id="structure">
      <report-structure />
    </div>
  </section>
</template>

<script>
import ReportStructure from "../components/report-structure/ReportStructure"

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  name: "Report",
  components: {
    ReportStructure
  },
  methods: {
    async generatePDF(){
      window.html2canvas = html2canvas;
      this.treatSVG();
      let elemento = document.getElementById("structure");
      var doc = new jsPDF({
        orientation: "l",
        unit: "pt",
        format: [540, 960]
      });

      doc.setFontSize(10);

      await doc.html(elemento, {
        html2canvas: {
          width: 1000
        }
      });
      doc.save("relatorio");
    },
    treatSVG(){
      var svgElements = document.body.querySelectorAll('svg');
      var svgPath = document.body.querySelectorAll('path');
      svgElements.forEach(function(item) {
        item.setAttribute("width", item.getBoundingClientRect().width * 2);
        item.setAttribute("height", item.getBoundingClientRect().height * 2);
        item.style.width = null;
        item.style.height = null;
      })
      svgPath.forEach(function(item){
        item.setAttribute("stroke", "black");
        item.setAttribute("stroke-width", 2);
      })
    }
  }
}
</script>

<style scoped>
.report-panel{
  display: grid;
  grid-template-columns: 20rem auto;
  gap: 2rem;
  margin: 2rem;
  padding-left: 2rem;
}

.config{
  border-radius: 1rem;
  background-color: #fdfdfd;
  padding: 1rem;
}

.structure{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* border: solid 2px black; */
  width: 960px;
}
</style>