import $http from "../axios";

const state = {
  opportunity: {},
  token: ''
};

const getters = {
  opportunity(state) {
    return state.opportunity;
  },
  token(state) {
    return state.token;
  }
};

const actions = {
  saveTableColumns(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post(`save-table-columns`, data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  setToken({ commit }, token){
    commit("SET_TOKEN", token);
  }
};

const mutations = {
  SET_OPPORTUNITY(state, opportunity) {
    state.opportunity = opportunity;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
