<template>
  <div
    id="cover-image-div"
    class="has-bg-img"
    :style="{ backgroundImage: 'url(' + coverImageURL + ')' }"
  ></div>
</template>

<script>
export default {
  props: ["coverImageURL"]
};
</script>

<style scoped>
.has-bg-img {
  background: center center;
  background-size: cover;
}
#cover-image-div {
  height: 300px;
}
</style>
