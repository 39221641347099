export const filter = {
  generalRequests(requests) {
    let requests_withoutAvailability = requests.filter((request) => {
      return request.visibleStatus == "Sem disponibilidade empresa"
    })
    let generalRequestsData = {
      number_of_requests: requests_withoutAvailability.length,
      number_of_students: 0,
      diferente_perfil: {
        quantity: 0,
        percentage: 0,
        students: 0,
        percentage_students: 0
      },
      tema_demandado: {
        quantity: 0,
        percentage: 0,
        students: 0,
        percentage_students: 0
      },
      gestor_ferias: {
        quantity: 0,
        percentage: 0,
        students: 0,
        percentage_students: 0
      },
      gestor_nao_retornou: {
        quantity: 0,
        percentage: 0,
        students: 0,
        percentage_students: 0
      },
      tema_expertise: {
        quantity: 0,
        percentage: 0,
        students: 0,
        percentage_students: 0
      },
      outros: {
        quantity: 0,
        percentage: 0,
        students: 0,
        percentage_students: 0
      }
    }
    for (let i = 0; i < requests_withoutAvailability.length; i++) {
      generalRequestsData.number_of_students += requests_withoutAvailability[i].eventInfo.attendeeQty;
      if (requests_withoutAvailability[i].noAvailabilityReason === 'Diferente do perfil-alvo') {
        generalRequestsData.diferente_perfil.quantity++;
        generalRequestsData.diferente_perfil.students += requests_withoutAvailability[i].eventInfo.attendeeQty || 0;
      } else if (requests_withoutAvailability[i].noAvailabilityReason === 'Tema muito demandado') {
        generalRequestsData.tema_demandado.quantity++;
        generalRequestsData.tema_demandado.students += requests_withoutAvailability[i].eventInfo.attendeeQty || 0;
      } else if (requests_withoutAvailability[i].noAvailabilityReason === 'Gestor em férias ou licença') {
        generalRequestsData.gestor_ferias.quantity++;
        generalRequestsData.gestor_ferias.students += requests_withoutAvailability[i].eventInfo.attendeeQty || 0;
      } else if (requests_withoutAvailability[i].noAvailabilityReason === 'Gestor não retornou') {
        generalRequestsData.gestor_nao_retornou.quantity++;
        generalRequestsData.gestor_nao_retornou.students += requests_withoutAvailability[i].eventInfo.attendeeQty || 0;
      } else if (requests_withoutAvailability[i].noAvailabilityReason === 'Tema não é de expertise') {
        generalRequestsData.tema_expertise.quantity++;
        generalRequestsData.tema_expertise.students += requests_withoutAvailability[i].eventInfo.attendeeQty || 0;
      } else {
        generalRequestsData.outros.quantity++;
        generalRequestsData.outros.students += requests_withoutAvailability[i].eventInfo.attendeeQty || 0;
      }
    }

    generalRequestsData.diferente_perfil.percentage = ((100 * generalRequestsData.diferente_perfil.quantity) / generalRequestsData.number_of_requests) || 0;
    generalRequestsData.diferente_perfil.percentage_students = ((100 * generalRequestsData.diferente_perfil.students) / generalRequestsData.number_of_students) || 0;

    generalRequestsData.tema_demandado.percentage = ((100 * generalRequestsData.tema_demandado.quantity) / generalRequestsData.number_of_requests) || 0;
    generalRequestsData.tema_demandado.percentage_students = ((100 * generalRequestsData.tema_demandado.students) / generalRequestsData.number_of_students) || 0;

    generalRequestsData.gestor_ferias.percentage = ((100 * generalRequestsData.gestor_ferias.quantity) / generalRequestsData.number_of_requests) || 0;
    generalRequestsData.gestor_ferias.percentage_students = ((100 * generalRequestsData.gestor_ferias.students) / generalRequestsData.number_of_students) || 0;

    generalRequestsData.gestor_nao_retornou.percentage = ((100 * generalRequestsData.gestor_nao_retornou.quantity) / generalRequestsData.number_of_requests) || 0;
    generalRequestsData.gestor_nao_retornou.percentage_students = ((100 * generalRequestsData.gestor_nao_retornou.students) / generalRequestsData.number_of_students) || 0;

    generalRequestsData.tema_expertise.percentage = ((100 * generalRequestsData.tema_expertise.quantity) / generalRequestsData.number_of_requests) || 0;
    generalRequestsData.tema_expertise.percentage_students = ((100 * generalRequestsData.tema_expertise.students) / generalRequestsData.number_of_students) || 0;

    return generalRequestsData;
  },
  withoutAvailabilityActions(requests) {
    var data = [];
    var requests_withoutAvailability = requests.filter((request) => {
      return request.visibleStatus == "Sem disponibilidade empresa"
    })
    let date = '';
    for (let i = 0; i < requests_withoutAvailability.length; i++) {
      if (requests_withoutAvailability[i].eventInfo.dateOptions.agreed.start) {
        date = new Date(requests_withoutAvailability[i].eventInfo.dateOptions.agreed.start)
      }
      try {
        data.push({
          data: date,
          tipo: requests_withoutAvailability[i].requestType,
          uf: requests_withoutAvailability[i].organization.state,
          universidade: requests_withoutAvailability[i].organization.university,
          categoria: requests_withoutAvailability[i].organization.category,
          oe_prof: requests_withoutAvailability[i].organization.name,
          cursos: requests_withoutAvailability[i].organization.membersPerCourse,
          publico: requests_withoutAvailability[i].eventInfo.attendeeQty,
          detalhes: null,
          request: requests_withoutAvailability[i]
        })
      } catch {
        data.push({
          data: date,
          tipo: requests_withoutAvailability[i].requestType,
          uf: requests_withoutAvailability[i].professor.state,
          universidade: requests_withoutAvailability[i].professor.university,
          categoria: "Professor",
          oe_prof: requests_withoutAvailability[i].professor.name,
          cursos: requests_withoutAvailability[i].eventInfo.subject.courses,
          publico: requests_withoutAvailability[i].eventInfo.attendeeQty,
          detalhes: null,
          request: requests_withoutAvailability[i]
        })
      }
    }
    data.sort(function (a, b) {
      return a.data - b.data;
    })
    data.splice(5);
    return data;
  }
}