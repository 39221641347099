<template>
  <div style="display: flex; background-color: #f7f7f7">
    <b-modal full-screen :active="isLoading" :can-cancel="false">
      <LoadingSvg />
    </b-modal>
    <business-header />
    <lateral-menu style="position: fixed" />
    <router-view style="margin-left: 14rem; width: 100%" />
    <b-modal full-screen :active="invalidToken && !validToken" :can-cancel="false">
      <InvalidToken />
    </b-modal>
    <Contact />
    <b-modal :active="isRequestModalActive" :can-cancel="false" full-screen>
      <b-button @click="isRequestModalActive = false" class="back" icon-left="arrow-left">
        Voltar
      </b-button>
      <RequestModal :individualRequest="individualRequest" />
    </b-modal>
  </div>
</template>

<script>
import businessHeader from "../components/commom/Header";
import LateralMenu from "../components/commom/LateralMenu";
import InvalidToken from "../components/commom/InvalidToken";
import LoadingSvg from "../components/commom/LoadingSvg";
import { commom } from "../mixins/commom";
import Contact from "../components/commom/Contact";
import RequestModal from "../components/requests/RequestModal";

export default {
  components: {
    businessHeader,
    LateralMenu,
    InvalidToken,
    LoadingSvg,
    Contact,
    RequestModal
  },
  mixins: [commom],
  data(){
    return{
      isLoading: true,
      validToken: false,
      invalidToken: false,
      token: "",
      contactModalIsActive: false,

      isRequestModalActive: false,
      individualRequest: null,
    }
  },
  methods: {
    getRequests() {
      if (this.token) {
        this.isLoading = true;
        this.$store
          .dispatch("getRequests", this.token)
          .then(res => {
            if (res.data.result === "ok") {
              this.validToken = true;
            } else {
              this.alertError(res.data);
            }
          })
          .catch(() => {
            this.alertError({ message: "UNEXPECTED_ERROR" });
            this.invalidToken = true;
          })
          .finally(() => {
            setTimeout((this.isLoading = false), 5500)
          });
      }
    },
    showRequest(request){
      this.individualRequest = request;
      this.isRequestModalActive = true;
    }
  },
  mounted() {
    if(this.$route.query.showRequestsToken){
      window.localStorage.setItem('token', this.$route.query.showRequestsToken);
    }
    this.token = this.$route.query.showRequestsToken || window.localStorage.getItem('token');
    this.$store.dispatch("setToken", this.$route.query.showRequestsToken || window.localStorage.getItem('token'));
    this.getRequests();
  }
};
</script>

<style scoped>
.back{
  border: solid 1px;
  margin-right: auto;
  margin-left: 2rem;
  margin-top: 2rem;
  border-radius: .5rem;
}
</style>
