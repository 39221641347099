<template>
  <section>
    <h2>Últimas Ações Realizadas</h2>
    <div>
      <div class="responsive-table">
        <!-- <table v-if="requestsData.length > 0">
          <thead>
            <tr>
              <th><span>Data</span></th>
              <th><span>Tipo de Ação</span></th>
              <th><span>Estado</span></th>
              <th><span>Universidade</span></th>
              <th><span>Categoria</span></th>
              <th><span>OE / Professor(a)</span></th>
              <th><span>Cursos</span></th>
              <th><span>Público</span></th>
              <th><span>Detalhes</span></th>
            </tr>
          </thead>
          <tr v-for="action in requestsData" :key="action.request._id">
            <td>
              <span>{{ moment(action.data).format("DD/MM/YY") }}</span>
            </td>
            <td>
              <span>{{ requestTypeText(action.tipo, 1) }}</span>
            </td>
            <td>
              <span>{{ action.uf }}</span>
            </td>
            <td>
              <span>{{ splitUniversity(action.universidade) }}</span>
            </td>
            <td>
              <span>{{ action.categoria }}</span>
            </td>
            <td>
              <span>{{ action.oe_prof }}</span>
            </td>
            <td>
              <b-button icon-right="list" @click="coursesList(action.cursos)" />
            </td>
            <td>
              <span>{{ action.publico }}</span>
            </td>
            <td>
              <b-button @click="$parent.$parent.showRequest(action.request)">
                <span>Ver Detalhes</span>
              </b-button>
            </td>
          </tr>
        </table>
        <div v-else>Nenhuma ação realizada</div> -->

        <b-table :data="requestsData" narrowed>
          <template slot-scope="props">
            <b-table-column field="data" label="Data" sortable>
              {{ moment(props.row.data).format("DD/MM/YY") }}
            </b-table-column>
            <b-table-column field="tipo" label="Tipo de Ação" sortable>
              {{ requestTypeText(props.row.tipo, 1) }}
            </b-table-column>
            <b-table-column field="uf" label="Estado" sortable>
              {{ props.row.uf }}
            </b-table-column>
            <b-table-column field="universidade" label="Universidade" sortable>
              {{ splitUniversity(props.row.universidade) }}
            </b-table-column>
            <b-table-column field="categoria" label="Categoria" sortable>
              {{ props.row.categoria }}
            </b-table-column>
            <b-table-column field="oe_prof" label="OE/Professor(a)" sortable>
              {{ props.row.oe_prof }}
            </b-table-column>
            <b-table-column field="cursos" label="Cursos">
              <b-button icon-right="list" @click="coursesList(props.row.cursos)" />
            </b-table-column>
            <b-table-column field="publico" label="Público" sortable>
              {{ props.row.publico }}
            </b-table-column>
            <b-table-column field="request" label="Detalhes">
              <b-button @click="$parent.$parent.showRequest(props.row.request)">
                <span>Ver Detalhes</span>
              </b-button>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { filter } from "./performed-actions-filter/performed-actions-filter";
import { request } from "../../../mixins/request";
import { momentMixin } from "../../../mixins/moment";
import { responsive } from "../../../mixins/responsive";
export default {
  name: "LatestActionsPerformed",
  props: ["requests"],
  mixins: [request, momentMixin, responsive],
  computed: {
    requestsData() {
      if (this.requests) {
        return filter.latestActionsPerformed(this.requests);
      } else return null;
    },
  },
  methods: {
    splitUniversity(name) {
      return name.split("-")[0];
    },
    coursesList(membersPerCourse) {
      if (membersPerCourse) {
        var courses = "";
        for (let i = 0; i < membersPerCourse.length; i++) {
          courses +=
            "- " + (membersPerCourse[i].course || membersPerCourse[i]) + "<br>";
        }
        this.$buefy.dialog.alert({
          title: "Cursos",
          message: courses,
          confirmText: "Ok",
        });
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 20px;
  color: #404040;
  font-weight: 700;
}
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem 1.5rem 1.5rem;
  display: block;
}
table {
  margin-top: 1rem;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
th {
  background-color: #ededed;
  height: 2rem;
}
tr {
  border-bottom: #f0f0f0 solid 1px;
}
.status {
  display: flex;
}
span {
  display: block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.1rem;
}

.button {
  padding-top: 0 !important;
  border-color: transparent;
}

.back {
  border: solid 1px;
  margin-right: auto;
  margin-left: 2rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
}

@media (max-width: 1170px) {
  .responsive-table {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
  table {
    width: auto;
    overflow: auto;
    white-space: nowrap;
    display: block;
  }
}
</style>