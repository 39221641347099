import { render, staticRenderFns } from "./LatestActionsPerformed.vue?vue&type=template&id=04f97984&scoped=true&"
import script from "./LatestActionsPerformed.vue?vue&type=script&lang=js&"
export * from "./LatestActionsPerformed.vue?vue&type=script&lang=js&"
import style0 from "./LatestActionsPerformed.vue?vue&type=style&index=0&id=04f97984&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f97984",
  null
  
)

export default component.exports