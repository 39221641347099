export const calendar = {
  constructor(initial_date, final_date, requests) {
    let initial = new Date(initial_date);
    let final = new Date(final_date);
    var calendar = new Array();
    if (initial.getFullYear() == final.getFullYear()) {
      for (let i = initial.getMonth(); i <= final.getMonth(); i++) {
        calendar.push(
          {
            month: months[i],
            monthIndex: i,
            year: initial.getFullYear(),
            days: getDays(initial.getFullYear(), i, requests)
          }
        );
      }
    } else {
      for (let i = initial.getMonth(); i <= 11; i++) {
        calendar.push(
          {
            month: months[i],
            monthIndex: i,
            year: initial.getFullYear(),
            days: getDays(initial.getFullYear(), i, requests)
          }
        )
      }
      for (let i = 0; i <= final.getMonth(); i++) {
        calendar.push(
          {
            month: months[i],
            monthIndex: i,
            year: final.getFullYear(),
            days: getDays(final.getFullYear(), i, requests)
          }
        )
      }
    }

    return calendar;
  }
}

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
];

const days_of_the_week = [
  "dom",
  "seg",
  "ter",
  "qua",
  "qui",
  "sex",
  "sab"
]

function getDays(year, month, requests) {
  let days_per_month = [];
  let days = [];
  if (year % 4 == 0) { //Se for ano bissexto, fevereiro tem 29 dias
    days_per_month = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  } else {
    days_per_month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  }
  for (let i = 1; i <= days_per_month[month]; i++) {
    let day = new Date(year, month, i);
    days.push(
      {
        date: i,
        day: days_of_the_week[day.getDay()],
        dateObject: new Date(year, month, i),
        events: getEvents(new Date(year, month, i), requests)
      }
    )
  }
  return days;
}

function getEvents(date, requests) {
  var requests_confirmed = requests.filter((request) => {
    return request.visibleStatus == "Confirmada"
  })
  var events = [];
  for (let i = 0; i < requests_confirmed.length; i++) {
    if (requests_confirmed[i].eventInfo.dateOptions.agreed) {
      let eventDate = new Date(requests_confirmed[i].eventInfo.dateOptions.agreed.start);
      let eventDateFormated = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate())

      if (eventDateFormated.getFullYear() == date.getFullYear() &&
        eventDateFormated.getMonth() == date.getMonth() &&
        eventDateFormated.getDate() == date.getDate()) {
        events.push(requests_confirmed[i]);
      }
    }
  }
  return events;
}