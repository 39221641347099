<template>
  <section>
    <div id="MeetingMinutes">
      <h1>ATAS DE REUNIÕES</h1>
      <div v-if="minutes.length > 0" class="minutes-list">
        <MeetingMinute
          v-for="(minute, i) in minutes"
          :key="i"
          :minute="minute"
          :companyName="opportunity.businessName"
        />
      </div>
      <div v-else>Não há atas disponíveis.</div>
    </div>
  </section>
</template>
<script>
import MeetingMinute from "../components/meeting-minutes/MeetingMinute";
import { mapGetters } from "vuex";
export default {
  name: "MeetingMinutes",
  components: {
    MeetingMinute,
  },
  computed: {
    ...mapGetters(["opportunity"]),
    minutes(){
      if(this.opportunity.meetings){
        return this.opportunity.meetings
      } else return new Array();
    }
  }
};
</script>

<style scoped>
#MeetingMinutes {
  margin: 2rem;
}
h1 {
  color: #404040;
  font-weight: bold;
  font-size: 24px;
}
.minutes-list {
  margin-top: 2rem;
}
</style>