<template>
  <section>
    <div id="OpportunityWebinars">
      <div class="dashboard-header">
        <h1>WEBINARS DA {{ companyName }} NO YOUTUBE DA EMPOWER</h1>
      </div>
      <div class="boxes" v-if="links.length > 0">
        <Webinar v-for="(link, i) in links" :key="i" :url="link" />
      </div>
      <div v-else>Não há webinars disponíveis.</div>
    </div>
  </section>
</template>

<script>
import Webinar from "../components/webinars/Webinar";
import { mapGetters } from "vuex";
export default {
  name: "OpportunityWebinars",
  components: {
    Webinar,
  },
  computed: {
    ...mapGetters(["opportunity"]),
    companyName() {
      if (this.opportunity.businessName) {
        return this.opportunity.businessName.toUpperCase();
      } else return "EMPRESA";
    },
    links(){
      if(this.opportunity.recordedWebinars) {
        return this.opportunity.recordedWebinars
      } else return new Array();
    }
  },
};
</script>

<style scoped>
#OpportunityWebinars {
  margin: 2rem;
}
h1 {
  color: #404040;
  font-weight: bold;
  font-size: 24px;
}
.boxes {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (max-width: 1200px) {
  .boxes {
    grid-template-columns: auto;
  }
}
</style>