const request = {
  methods: {
    requestTypeText(type, capitalization = 0) {
      // 0 --> all lower case
      return capitalization === 0
        ? type === "visit"
          ? "visita"
          : type === "lecture"
            ? "palestra"
            : type === "webinar"
              ? "webinar"
              : type === "training"
                ? "treinamento"
                : type === "mentoring"
                  ? "mentoria"
                  : type === "sponsorship"
                    ? "patrocínio"
                    : type === "project"
                      ? "projeto"
                      : type === "other"
                        ? "outra ação"
                        : type === "lesson"
                          ? "participação em aula"
                          : type === "otherProf"
                            ? "outra ação (professor)"
                            : type === "research"
                              ? "pesquisa"
                              : type === "visitProf"
                                ? "visita (professor)"
                                : "-"
        : capitalization === 1 // 1 --> first upper case
          ? type === "visit"
            ? "Visita"
            : type === "lecture"
              ? "Palestra"
              : type === "webinar"
                ? "Webinar"
                : type === "training"
                  ? "Treinamento"
                  : type === "mentoring"
                    ? "Mentoria"
                    : type === "sponsorship"
                      ? "Patrocínio"
                      : type === "project"
                        ? "Projeto"
                        : type === "other"
                          ? "Outra Ação"
                          : type === "lesson"
                            ? "Participação em Aula"
                            : type === "otherProf"
                              ? "Outra Ação (Professor)"
                              : type === "research"
                                ? "Pesquisa"
                                : type === "visitProf"
                                  ? "Visita (Professor)"
                                  : "-"
          : // 2 --> all upper case
          type === "visit"
            ? "VISITA"
            : type === "lecture"
              ? "PALESTRA"
              : type === "webinar"
                ? "WEBINAR"
                : type === "training"
                  ? "TREINAMENTO"
                  : type === "mentoring"
                    ? "MENTORIA"
                    : type === "sponsorship"
                      ? "PATROCÍNIO"
                      : type === "project"
                        ? "PROJETO"
                        : type === "other"
                          ? "OUTRA AÇÃO"
                          : type === "lesson"
                            ? "PARTICIPAÇÃO EM AULA"
                            : type === "otherProf"
                              ? "OUTRA AÇÃO (PROFESSOR)"
                              : type === "research"
                                ? "PESQUISA"
                                : type === "visitProf"
                                  ? "VISITA (PROFESSOR)"
                                  : "-"
    },
    requestStatus(status) {
      return status === "newRequest"
        ? "Solicitação recebida"
        : status === "underEvaluation"
          ? "Aguardando resposta"
          : status === "confirmed"
            ? "Solicitação aceita"
            : status === "alignment"
              ? "Alinhamento com o gestor"
              : status === "preEvent"
                ? "Preparativos pré-evento"
                : status === "waitingCounterparts"
                  ? "Enviar contrapartidas"
                  : status === "buildingCase"
                    ? "Construindo case"
                    : status === "finishedWithCounterparts"
                      ? "Ação finalizada - com contrapartidas"
                      : status === "finishedWithoutCounterparts"
                        ? "Ação finalizada - sem contrapartidas"
                        : status === "noAvailability"
                          ? "Sem disponibilidade empresa"
                          : status === "canceledByOrganization"
                            ? "Cancelado pela OE"
                            : "---";
    },
    requestStatusForBusiness(status) {
      return status === "underEvaluation"
        ? "Em Avaliação"
        : status === "confirmed" ||
          status === "alignment" ||
          status === "preEvent"
          ? "Confirmada"
          : status === "waitingCounterparts" ||
            status === "buildingCase" ||
            status === "finishedWithCounterparts" ||
            status === "finishedWithoutCounterparts"
            ? "Realizada"
            : status === "noAvailability"
              ? "Sem disponibilidade empresa"
              : status === "canceledByOrganization"
                ? "Cancelado pela OE"
                : "---";
    },
    requestProgress(progress) {
      return progress === "delayed"
        ? "Avaliação Atrasada"
        : progress === "expired"
          ? "Prazo Expirado"
          : progress === "ok"
            ? "Em Dia"
            : "-";
    },
    requestTypeTreatmentPronoun(type, capitalization = 0) {
      return capitalization === 0 // lower case
        ? type === "webinar" ||
          type === "training" ||
          type === "sponsorship" ||
          type === "project"
          ? "o"
          : "a"
        : type === "webinar" ||
          type === "training" ||
          type === "sponsorship" ||
          type === "project"
          ? "O"
          : "A";
    },
    labelColor(requestType) {
      return requestType === "visit"
        ? "is-warning"
        : requestType === "lecture"
          ? "is-info"
          : requestType === "webinar"
            ? "is-danger"
            : requestType === "training"
              ? "is-success"
              : requestType === "sponsorship"
                ? "is-primary"
                : requestType === "project"
                  ? "is-empower-pink"
                  : requestType === "mentoring"
                    ? "is-light"
                    : "is-dark";
    },
    hasSpecificTime(requestType) {
      return !(
        requestType === "visit" ||
        requestType === "other" ||
        requestType === "sponsorship" ||
        requestType === "project"
      );
    }
  },

  computed: {
    isVisit() {
      return this.request.requestType === "visit";
    },
    isLecture() {
      return this.request.requestType === "lecture";
    },
    isMentoring() {
      return this.request.requestType === "mentoring";
    },
    isTraining() {
      return this.request.requestType === "training";
    },
    isSponsorship() {
      return this.request.requestType === "sponsorship";
    },
    isProject() {
      return this.request.requestType === "project";
    },
    isWebinar() {
      return this.request.requestType === "webinar";
    },
    isOther() {
      return this.request.requestType === "other";
    },
    isLesson() {
      return this.request.requestType === "lesson";
    },
    isResearch() {
      return this.request.requestType === "research";
    },
    isVisitProf() {
      return this.request.requestType === "visit";
    },
    isOtherProf() {
      return this.request.requestType === "other";
    },
    availablePlants() {
      return this.opportunity.plants;
    },
    availableLectureThemes() {
      return this.opportunity.lecture.themes;
    }
  }
};

export { request };
