<template>
  <section>
    <div>
      <div class="total-numbers">
        <data-box
          :number="requestData.quantity"
          message="Ações Confirmadas"
          color="#b045d8"
        />
        <data-box
          :number="requestData.number_of_students"
          message="Estudantes que participarão <br> das Ações Confirmadas"
          color="#b045d8"
        />
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th><span>Tipo</span></th>
              <th style="text-align: center"><span>Ações Confirmadas</span></th>
              <th style="text-align: center"><span>Estudantes</span></th>
            </tr>
          </thead>
          <tr v-for="action in requestData.actions" :key="action.name">
            <td class="status" v-if="action.number > 0">
              <span>{{action.name}}</span>
            </td>
            <td v-if="action.number > 0">
              <span>{{action.number}}</span>
              <span>{{(action.percentage).toFixed(1)}}%</span>
            </td>
            <td v-if="action.number > 0">
              <span>{{action.number_of_students}}</span>
              <span>{{(action.percentage_of_students).toFixed(1)}}%</span>
            </td>
          </tr>

          <tr>
            <th class="status">
              <span>Total</span>
            </th>
            <th class="secondary-head">
              <span>{{requestData.quantity}}</span>
              <span>100%</span>
            </th>
            <th class="secondary-head">
              <span>{{requestData.number_of_students}}</span>
              <span>100%</span>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import DataBox from "../../commom/DataBox";
import { filter } from "./confirmed-actions-filter/confirmed-actions-filter"
export default {
  name: "GeneralRequests",
  components: {
    DataBox,
  },
  props: ["requests"],
  computed: {
    requestData(){
      if(this.requests){
        return filter.confirmedActions(this.requests);
      } else return null;
    }
  }
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem;
}
.total-numbers {
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  gap: 3%;
}
table {
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
}
th {
  background-color: #ededed;
  height: 2rem;
}
tr {
  border-bottom: #f0f0f0 solid 1px;
}
.status {
  display: flex;
}
span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
table td:not([align]){
  text-align: center !important;
}
.secondary-head{
  text-align: center;
}
</style>