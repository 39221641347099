import { render, staticRenderFns } from "./TypesTable.vue?vue&type=template&id=f46f6db4&scoped=true&"
import script from "./TypesTable.vue?vue&type=script&lang=js&"
export * from "./TypesTable.vue?vue&type=script&lang=js&"
import style0 from "./TypesTable.vue?vue&type=style&index=0&id=f46f6db4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f46f6db4",
  null
  
)

export default component.exports