<template>
  <section>
    <h2>Distribuição por Tipo de Ação</h2>
    <div>
      <div>
        <table>
          <thead>
            <tr>
              <th class="status"><span>Tipo</span></th>
              <th class="secondary-head"><span>Ações Realizadas</span></th>
              <th class="secondary-head"><span>Estudantes</span></th>
            </tr>
          </thead>

          <tr v-for="action in requestData.actions" :key="action.name">
            <td class="status" v-if="action.number > 0">
              <span>{{action.name}}</span>
            </td>
            <td v-if="action.number > 0">
              <span>{{action.number}}</span>
              <span>{{(action.percentage).toFixed(1)}}%</span>
            </td>
            <td v-if="action.number > 0">
              <span>{{action.number_of_students}}</span>
              <span>{{(action.percentage_of_students).toFixed(1)}}%</span>
            </td>
          </tr>

          <tr>
            <th class="status">
              <span>Total</span>
            </th>
            <th style="text-align: center !important;">
              <span>{{requestData.quantity}}</span>
              <span>100%</span>
            </th>
            <th class="secondary-head">
              <span>{{requestData.number_of_students}}</span>
              <span>100%</span>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { filter } from "./performed-actions-filter/performed-actions-filter"
export default {
  name: "DistributionByType",
  props: ["requests"],
  computed: {
    requestData(){
      if(this.requests){
        return filter.distribuitionByType(this.requests);
      } else return null;
    }
  }
}
</script>

<style scoped>
h2{
  font-size: 20px;
  color: #404040;
  font-weight: 700;
}
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
}
table {
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
}
th {
  background-color: #ededed;
  height: 2rem;
}
tr {
  border-bottom: #f0f0f0 solid 1px;
}
table td{
  text-align: center !important;
}
table .secondary-head{
  text-align: center !important;
}

table td span{
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.status {
  display: flex;
}
span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.status span{
  margin-left: 1.5rem;
}
</style>