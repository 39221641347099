<template>
  <div class="data-box" :style="'background-color: ' + color">
    <p>{{ (number).toLocaleString('pt-BR') }}</p>
    <span v-html="message"></span>
  </div>
</template>

<script>
export default {
  name: "DataBox",
  props: ["number", "message", "color"],
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&display=swap');
.data-box {
  color: white;
  padding: 1rem;
  box-sizing: border-box;
  height: 9rem;
}
.data-box p {
  font-size: 2.1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.data-box span {
  font-size: 1.2rem;
}
</style>