<template>
  <section>
    <div>
      <div class="total-numbers">
        <data-box
          :number="requestData.number_of_actions_requested"
          message="Ações Solicitadas"
          color="#00B991"
        />
        <data-box
          :number="requestData.esteemed_students"
          message="Estudantes estimados <br> para as Ações Solicitadas"
          color="#00B991"
        />
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th><span class="table-head text-head">Status</span></th>
              <th>
                <span class="text-head" style="text-align: center"
                  >Solicitações</span
                >
              </th>
              <th>
                <span class="text-head" style="text-align: center"
                  >Estudantes</span
                >
              </th>
            </tr>
          </thead>
          <tr>
            <td class="status">
              <div class="color-circle" style="background-color: #556ee6"></div>
              <span>Realizadas</span>
            </td>
            <td>
              <span v-if="requestData.performed.number_of_requests >= 10">{{
                requestData.performed.number_of_requests
              }}</span>
              <span v-else
                >0{{ requestData.performed.number_of_requests }}</span
              >
              <span v-if="requestData.performed.percentage_of_requests >= 10"
                >{{
                  requestData.performed.percentage_of_requests.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.performed.percentage_of_requests.toFixed(1)
                }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.performed.number_of_students >= 10">{{
                (requestData.performed.number_of_students).toLocaleString('pt-BR')
              }}</span>
              <span v-else
                >0{{ requestData.performed.number_of_students }}</span
              >
            </td>
          </tr>

          <tr>
            <td class="status">
              <div class="color-circle" style="background-color: #c34df0"></div>
              <span>Confirmadas</span>
            </td>
            <td>
              <span v-if="requestData.confirmed.number_of_requests >= 10">{{
                requestData.confirmed.number_of_requests
              }}</span>
              <span v-else
                >0{{ requestData.confirmed.number_of_requests }}</span
              >
              <span v-if="requestData.confirmed.percentage_of_requests >= 10"
                >{{
                  requestData.confirmed.percentage_of_requests.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.confirmed.percentage_of_requests.toFixed(1)
                }}%</span
              >
            </td>
            <td>
              <span v-if="requestData.confirmed.number_of_students >= 10">{{
                (requestData.confirmed.number_of_students).toLocaleString('pt-BR')
              }}</span>
              <span v-else
                >0{{ requestData.confirmed.number_of_students }}</span
              >
            </td>
          </tr>

          <tr>
            <td class="status">
              <div class="color-circle" style="background-color: #ffc92f"></div>
              <span>Em Avaliação</span>
            </td>
            <td>
              <span
                v-if="requestData.under_evaluation.number_of_requests >= 10"
                >{{ requestData.under_evaluation.number_of_requests }}</span
              >
              <span v-else
                >0{{ requestData.under_evaluation.number_of_requests }}</span
              >
              <span
                v-if="requestData.under_evaluation.percentage_of_requests >= 10"
                >{{
                  requestData.under_evaluation.percentage_of_requests.toFixed(
                    1
                  )
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.under_evaluation.percentage_of_requests.toFixed(
                    1
                  )
                }}%</span
              >
            </td>
            <td>
              <span
                v-if="requestData.under_evaluation.number_of_students >= 10"
                >{{ (requestData.under_evaluation.number_of_students).toLocaleString('pt-BR') }}</span
              >
              <span v-else
                >0{{ requestData.under_evaluation.number_of_students }}</span
              >
            </td>
          </tr>

          <tr>
            <td class="status">
              <div class="color-circle" style="background-color: #747474"></div>
              <span>Sem Disponibilidade</span>
            </td>
            <td>
              <span
                v-if="requestData.no_availability.number_of_requests >= 10"
                >{{ requestData.no_availability.number_of_requests }}</span
              >
              <span v-else
                >0{{ requestData.no_availability.number_of_requests }}</span
              >
              <span
                v-if="requestData.no_availability.percentage_of_requests >= 10"
                >{{
                  requestData.no_availability.percentage_of_requests.toFixed(1)
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.no_availability.percentage_of_requests.toFixed(1)
                }}%</span
              >
            </td>
            <td>
              <span
                v-if="requestData.no_availability.number_of_students >= 10"
                >{{ (requestData.no_availability.number_of_students).toLocaleString('pt-BR') }}</span
              >
              <span v-else
                >0{{ requestData.no_availability.number_of_students }}</span
              >
            </td>
          </tr>

          <tr>
            <td class="status">
              <div class="color-circle" style="background-color: #c7c7c7"></div>
              <span>Canceladas pelas OEs</span>
            </td>
            <td>
              <span
                v-if="requestData.canceled_by_the_OEs.number_of_requests >= 10"
                >{{ requestData.canceled_by_the_OEs.number_of_requests }}</span
              >
              <span v-else
                >0{{ requestData.canceled_by_the_OEs.number_of_requests }}</span
              >
              <span
                v-if="
                  requestData.canceled_by_the_OEs.percentage_of_requests >= 10
                "
                >{{
                  requestData.canceled_by_the_OEs.percentage_of_requests.toFixed(
                    1
                  )
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.canceled_by_the_OEs.percentage_of_requests.toFixed(
                    1
                  )
                }}%</span
              >
            </td>
            <td>
              <span
                v-if="requestData.canceled_by_the_OEs.number_of_students >= 10"
                >{{ (requestData.canceled_by_the_OEs.number_of_students).toLocaleString('pt-BR') }}</span
              >
              <span v-else
                >0{{ requestData.canceled_by_the_OEs.number_of_students }}</span
              >
            </td>
          </tr>

          <tr>
            <td class="status">
              <div class="color-circle" style="background-color: #c7c7c7"></div>
              <span>Diferente do Perfil-Alvo</span>
            </td>
            <td>
              <span
                v-if="
                  requestData.different_from_the_target_profile
                    .number_of_requests >= 10
                "
                >{{
                  requestData.different_from_the_target_profile
                    .number_of_requests
                }}</span
              >
              <span v-else
                >0{{
                  requestData.different_from_the_target_profile
                    .number_of_requests
                }}</span
              >
              <span
                v-if="
                  requestData.different_from_the_target_profile
                    .percentage_of_requests >= 10
                "
                >{{
                  requestData.different_from_the_target_profile.percentage_of_requests.toFixed(
                    1
                  )
                }}%</span
              >
              <span v-else
                >0{{
                  requestData.different_from_the_target_profile.percentage_of_requests.toFixed(
                    1
                  )
                }}%</span
              >
            </td>
            <td>
              <span
                v-if="
                  requestData.different_from_the_target_profile
                    .number_of_students >= 10
                "
                >{{
                  (requestData.different_from_the_target_profile
                    .number_of_students).toLocaleString('pt-BR')
                }}</span
              >
              <span v-else
                >0{{
                  requestData.different_from_the_target_profile
                    .number_of_students
                }}</span
              >
            </td>
          </tr>

          <tr>
            <th class="status">
              <span class="table-head">Total</span>
            </th>
            <th class="result-number" style="text-align: center">
              <span v-if="requestData.number_of_actions_requested >= 10">{{
                requestData.number_of_actions_requested
              }}</span>
              <span v-else>{{ requestData.number_of_actions_requested }}</span>
              <span>100%</span>
            </th>
            <th style="text-align: center">
              <span v-if="requestData.esteemed_students >= 10">{{
                (requestData.esteemed_students).toLocaleString('pt-BR')
              }}</span>
              <span v-else>0{{ requestData.esteemed_students }}</span>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import DataBox from "../../commom/DataBox";
import { filter } from "./requested-actions-filter/requested-actions-filter";

export default {
  name: "GeneralRequests",
  props: ["requests"],
  components: {
    DataBox,
  },
  computed: {
    requestData() {
      if (this.requests) {
        return filter.generalRequests(this.requests);
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1.5rem;
}
.total-numbers {
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  gap: 3%;
}
table {
  margin-top: 2rem;
  width: 100%;
}
th {
  background-color: #ededed;
  height: 2rem;
}
tr {
  border-bottom: #f0f0f0 solid 1px;
  height: 2rem;
}
.color-circle {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
}
.color-circle:hover {
  opacity: 0.8;
}
.status {
  display: flex;
}
span {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  margin-top: 0.3rem;
  margin-bottom: 0.1rem;
}
.text-head {
  margin-top: 0.3rem;
  display: block;
}
.table-head {
  margin-left: 1rem;
}

table td:not([align]) {
  text-align: center !important;
}

table th:not([align]) .result-number {
  text-align: center !important;
}
</style>