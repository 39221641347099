<template>
  <section>
    <div class="request" @click="isRequestModalActive = true">
      <div class="colored-band" :class="request.requestProgress"></div>
      <div class="request-content">
        <div class="subtitle-status">
          <span
            >Solicitada em:
            {{ moment(request.createdAt).format("DD/MM/YYYY") }}</span
          >
          <span class="stripe">|</span>
          <span>Status: {{ request.visibleStatus }}</span>
          <span v-if="request.visibleStatus == 'Em Avaliação'">
            <span v-if="updateBusiness">
              <span class="stripe">|</span>
              Atualizado via Business:
              <span v-if="request.confirmedViaBusiness">Aceito ✅</span>
              <span v-else-if="request.noAvailabilityViaBusiness">Recusado ❌</span>
            </span>
          </span>
        </div>
        <b-field class="title-type">
          <b-icon
            icon="heart"
            size="is-small"
            :class="request.isFavorite ? 'heatRed' : 'heatGray'"
            class="favorite"
          />
          <span v-if="request.organization"
            >{{ requestTypeText(request.requestType, 2) }} -
            {{ request.organization.name }} ({{
              request.organization.university.split("-")[0]
            }}
            - {{ request.organization.city }}/{{
              states[request.organization.state]
            }})</span
          >
          <span v-else
            >{{ requestTypeText(request.requestType, 2) }} -
            {{ request.professor.name }} ({{
              request.professor.university.split("-")[0]
            }}
            - {{ request.professor.city }}/{{
              states[request.professor.state]
            }})</span
          >
        </b-field>
        <div v-if="request.eventInfo.dateOptions.agreed.start">
          <span
            >Data:
            {{
              moment(request.eventInfo.dateOptions.agreed.start).format(
                "DD/MM/YYYY"
              )
            }}</span
          >
          <span>
            das
            {{ moment(request.eventInfo.dateOptions.agreed.start, "HH:mm") }}
            às
            {{
              moment(request.eventInfo.dateOptions.agreed.end, "HH:mm")
            }}</span
          >
        </div>
        <div v-else>
          <span>Datas Sugeridas: {{ dateOptions }}</span>
          <span class="stripe" v-if="request.eventInfo.attendeeQty">|</span>
          <span v-if="request.eventInfo.realAttendeeQty">
            Público Presente: {{  request.eventInfo.realAttendeeQty }}
          </span>
          <span v-else-if="request.eventInfo.attendeeQty"
            >Público Estimado: {{ request.eventInfo.attendeeQty }}</span
          >
        </div>
        <div v-if="request.eventInfo.title">
          <span>Tema: {{ request.eventInfo.title }}</span>
          <span v-if="request.eventInfo.speaker">
            com {{ request.eventInfo.speaker }}</span
          >
        </div>
        <div v-else-if="request.eventInfo.suggestedThemes.length > 0">
          <span>
            Possíveis Temas:
            <span
              v-for="(tema, i) in request.eventInfo.suggestedThemes"
              :key="i"
            >
              {{ tema }}
              <span v-if="request.eventInfo.suggestedThemes[i + 1]">, </span>
              <span v-else>.</span>
            </span>
          </span>
        </div>
        <div v-if="request.eventInfo.plant">
          Planta da Visita: {{ request.eventInfo.plant }}
        </div>
        <div v-if="request.eventInfo.eventType && request.organization">
          Tipo de ação: {{ request.eventInfo.eventType }}
        </div>
      </div>
      <div class="alert">
        <b-icon
            icon="exclamation-circle"
            size="is-large"
            class="red-circle"
            v-if="requestAttention"
          />
      </div>
    </div>
    <b-modal :active="isRequestModalActive" :can-cancel="false" full-screen>
      <b-button @click="isRequestModalActive = false" class="back" icon-left="arrow-left">
        Voltar
      </b-button>
      <RequestModal
        :individualRequest="request"
        :opportunityName="opportunityName"
      />
    </b-modal>
  </section>
</template>

<script>
import { momentMixin } from "../../mixins/moment";
import { request } from "../../mixins/request";
import { statesNamesToAbbreviation } from "../../assets/js/states-name-to-abbreviation";
import RequestModal from "./RequestModal.vue";
export default {
  name: "Request",
  props: ["request", "opportunityName"],
  mixins: [momentMixin, request],
  components: {
    RequestModal,
  },
  data() {
    return {
      isRequestModalActive: false,
    };
  },
  computed: {
    states() {
      return statesNamesToAbbreviation;
    },
    dateOptions() {
      var dates = [];
      if (this.request.eventInfo.dateOptions.first.start) {
        dates.push(
          this.moment(this.request.eventInfo.dateOptions.first.start).format(
            "DD/MM/YYYY"
          )
        );
      }
      if (this.request.eventInfo.dateOptions.second.start) {
        dates.push(
          this.moment(this.request.eventInfo.dateOptions.second.start).format(
            "DD/MM/YYYY"
          )
        );
      }
      if (this.request.eventInfo.dateOptions.third.start) {
        dates.push(
          this.moment(this.request.eventInfo.dateOptions.third.start).format(
            "DD/MM/YYYY"
          )
        );
      }
      if (dates.length == 3) {
        return dates[0] + ", " + dates[1] + " e " + dates[2];
      } else if (dates.length == 2) {
        return dates[0] + " e " + dates[1];
      } else if (dates.length == 1) {
        return dates[0];
      } else return "Sem data definida";
    },
    updateBusiness(){
      if (
        this.request.confirmedViaBusiness === undefined &&
        this.request.noAvailabilityViaBusiness === undefined
      ) {
        return false;
      } else if (
        this.request.confirmedViaBusiness ||
        this.request.noAvailabilityViaBusiness
      ) {
        return true;
      } else return false;
    },
    requestAttention(){
      if(this.request.visibleStatus === 'Em Avaliação'){
        if(this.request.confirmedViaBusiness || this.request.noAvailabilityViaBusiness){
          return false
        } else return true
      } else {
        return false
      }
    }
  },
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  margin-bottom: 1rem;
}
section:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.request {
  display: grid;
  grid-template-columns: 0.6rem auto 3rem;
}
.request:hover {
  cursor: pointer;
}
.colored-band {
  height: 100%;
  width: 0.7rem;
}

.ok {
  background-color: #33c7a7;
}
.delayed {
  background-color: #ffd459;
}
.expired {
  background-color: #fe7687;
}
.request-content {
  margin: 1rem;
}

.subtitle-status {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: #646464;
}

.stripe {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.title-type {
  font-weight: bold;
}

.back{
  border: solid 1px;
  margin-right: auto;
  margin-left: 2rem;
  margin-top: 2rem;
  border-radius: .5rem;
}

.red-circle{
  color: #fc5353;
}

.favorite svg {
  height: 1rem;
}

.favorite {
  margin-top: 0.3rem;
  margin-right: 0.5rem;
}

.heatRed {
  color: #fd7a7a;
}

.heatGray {
  color: #b5b5b5;
}

.field:not(:last-child) {
  margin-bottom: 0;
}

.modal-background {
  background-color: #ffffff !important;
}
</style>