import $http from "../axios";

const actions = {
  downloadReport(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .get(`report?token=${data.token}`, {
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          },
          responseType: "arraybuffer"
        })
        .then(res => {
          const blob = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const e = document.createEvent("MouseEvents");
          const a = document.createElement("a");
          const currentDate = new Date();
          const date = currentDate.getDate();
          const month = currentDate.getMonth() + 1;
          const year = currentDate.getFullYear();
          // eslint-disable-next-line prettier/prettier
          const yyyymmdd = `${year}-${(month < 10 ? "0" + month : month)}-${(date < 10 ? "0" + date : date)}`;
          const filename = `[Parceria Empower & ${data.businessName}] Relatório de Solicitações _${yyyymmdd}.xlsx`;
          a.download = filename;
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            a.download,
            a.href
          ];
          // eslint-disable-next-line prettier/prettier
          e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          a.dispatchEvent(e);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

export default {
  actions
};
