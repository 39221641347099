<template>
  <section>
    <div id="requests">
      <div class="requests-header">
        <h1>SOLICITAÇÕES - TODAS</h1>
        <div class="filters">
          <div class="favorite" @click="isFavoriteMethod()">
            <b-icon icon="heart" size="is-large" class="favorite" :class="isFavorite ? 'heatRed' : 'heatGray'" />
          </div>
          <b-select
            placeholder="Status da Ação"
            class="action-type"
            v-model="status"
          >
            <option value="">Todos os Status</option>
            <option value="Em Avaliação">Em avaliação</option>
            <option value="Confirmada">Confirmada</option>
            <option value="Realizada">Realizada</option>
            <option value="Sem disponibilidade empresa">
              Sem disponibilidade da empresa
            </option>
            <option value="Cancelado pela OE">Cancelado pela OE</option>
          </b-select>
          <b-select
            placeholder="Tipo de Ação"
            class="action-type"
            v-model="type"
          >
            <option value="">Todas as Ações</option>
            <option value="webinar">Webinars</option>
            <option value="lecture">Palestras</option>
            <option value="training">Treinamentos</option>
            <option value="visit">Visitas Técnicas</option>
            <option value="mentoring">Mentorias</option>
            <option value="project">Projetos</option>
            <option value="sponsorship">Patrocínios</option>
            <option value="other">Outras Ações</option>
          </b-select>
          <b-select placeholder="Ordenar por:" v-model="order">
            <option value="">Ordenar por</option>
            <option value="data-solicitacao-antigas">
              Data de Solicitação (Mais antigas)
            </option>
            <option value="data-solicitacao-novas">
              Data de Solicitação (Mais novas)
            </option>
            <option value="data-realizacao-antigas">
              Data de Realização (Mais antigas)
            </option>
            <option value="data-realizacao-novas">
              Data de Realização (Mais novas)
            </option>
            <option value="status">Status</option>
          </b-select>
        </div>
      </div>
      <div class="status-caption">
        <div class="status-color"> 
          <div class="ball" style="background-color: #33c7a7;"></div>
          <span>EM DIA</span>
        </div>
        <div class="status-color"> 
          <div class="ball" style="background-color: #ffd459;"></div>
          <span>ATRASADO</span>
        </div>
        <div class="status-color"> 
          <div class="ball" style="background-color: #fe7687;"></div>
          <span>EXPIRADO</span>
        </div>
      </div>
      <hr />

      <Request
        v-for="request in filteredRequests"
        :key="request._id"
        :request="request"
        :opportunityName="getOpportunityName"
      />
      <span v-if="filteredRequests.length == 0"
        >Não há solicitações no momento.</span
      >
    </div>
  </section>
</template>

<script>
import Request from "../components/requests/Request";
import { mapGetters } from "vuex";
export default {
  name: "Requests",
  components: {
    Request,
  },
  data() {
    return {
      type: "",
      order: "",
      status: "",
      isFavorite: false
    };
  },
  computed: {
    ...mapGetters(["requests", "contract", "opportunity"]),
    filteredRequests() {
      var newList = this.planRequests;
      if (this.order === "data-solicitacao-antigas" || this.order === "") {
        newList = newList.sort((a, b) => {
          if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return -1;
          }
          if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
          }
          return 0;
        });
      }
      if (this.order === "data-solicitacao-novas") {
        newList = newList.sort((a, b) => {
          if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return -1;
          }
          if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return 1;
          }
          return 0;
        });
      }
      if (this.order === "data-realizacao-antigas") {
        newList = newList.sort((a, b) => {
          if (
            new Date(a.eventInfo.dateOptions.agreed.start) <
            new Date(b.eventInfo.dateOptions.agreed.start)
          ) {
            return -1;
          }
          if (
            new Date(a.eventInfo.dateOptions.agreed.start) >
            new Date(b.eventInfo.dateOptions.agreed.start)
          ) {
            return 1;
          }
          return 0;
        });
      }
      if (this.order === "data-realizacao-novas") {
        newList = newList.sort((a, b) => {
          if (
            new Date(a.eventInfo.dateOptions.agreed.start) >
            new Date(b.eventInfo.dateOptions.agreed.start)
          ) {
            return -1;
          }
          if (
            new Date(a.eventInfo.dateOptions.agreed.start) <
            new Date(b.eventInfo.dateOptions.agreed.start)
          ) {
            return 1;
          }
          return 0;
        });
      }
      if (this.order === "status") {
        newList = this.orderByStatus(this.planRequests);
      }

      if (this.type) {
        newList = newList.filter((request) => request.requestType == this.type);
      }

      if (this.status != "") {
        newList = this.filteredByStatus(newList);
      }

      if(this.isFavorite){
        newList = newList.filter((request) => request.isFavorite == true);
      }

      return newList;
    },
    planRequests() {
      if (this.contract) {
        return this.requests.filter(
          (request) =>
            new Date(request.createdAt) > new Date(this.contract.dates[0]) &&
            new Date(request.createdAt) < new Date(this.contract.dates[1])
        );
      } else return this.requests;
    },
    getOpportunityName() {
      return this.opportunity.businessName;
    },
  },
  methods: {
    orderByStatus(requests) {
      let newList = [];
      let emAvaliacao = requests.filter(
        (request) => request.visibleStatus == "Em Avaliação"
      );
      let confirmada = requests.filter(
        (request) => request.visibleStatus == "Confirmada"
      );
      let realizada = requests.filter(
        (request) => request.visibleStatus == "Realizada"
      );
      let semDisponibilidade = requests.filter(
        (request) => request.visibleStatus == "Sem disponibilidade empresa"
      );
      let canceladoOE = requests.filter(
        (request) => request.visibleStatus == "Cancelado pela OE"
      );
      let outro = requests.filter((request) => request.visibleStatus == "---");

      newList = emAvaliacao.concat(
        confirmada,
        realizada,
        semDisponibilidade,
        canceladoOE,
        outro
      );

      return newList;
    },
    filteredByStatus(requests) {
      if (this.status != "") {
        return requests.filter(
          (request) => request.visibleStatus == this.status
        );
      } else return requests;
    },
    isFavoriteMethod(){
      this.isFavorite = !this.isFavorite;
    }
  },
};
</script>

<style scoped>
#requests {
  margin: 2rem;
}
h1 {
  color: #404040;
  font-weight: bold;
  font-size: 24px;
}
.requests-header {
  display: flex;
}
.action-type {
  margin-left: auto;
}
.filters {
  display: flex;
  margin-left: auto;
  gap: 0.5rem;
}
.filters .icon {
  margin-top: -0.4rem;
}
hr {
  background-color: transparent;
  height: 1px;
  margin: 0.8rem 0;
}

.heatRed {
  color: #fd7a7a;
}

.heatGray {
  color: #b5b5b5;
}

.favorite{
  cursor: pointer;
}

.status-caption{
  display: flex;
  width: 30rem;
  gap: 2rem;
}
.status-color{
  display: flex;
  gap: .3rem;
}
.ball{
  width: 1rem;
  height: 1rem;
  border-radius: .5rem;
  margin-top: .35rem;
}
</style>