<template>
  <div class="request-modal">
    <div class="header">
      <span>
        <span
          class="status-ball"
          :class="individualRequest.requestProgress"
        ></span>
        {{ requestStatus(individualRequest.requestStatus).toUpperCase() }}
        ({{ requestProgress(individualRequest.requestProgress).toUpperCase() }})
        - {{ requestTypeText(individualRequest.requestType, 2) }}
        <span v-if="individualRequest.visibleStatus == 'Em Avaliação'">
          <span v-if="!canYouAnswer">
            <span class="stripe">|</span>
            Atualizado via Business:
            <span v-if="individualRequest.confirmedViaBusiness">Aceito ✅</span>
            <span v-else-if="individualRequest.noAvailabilityViaBusiness"
              >Recusado ❌</span
            >
          </span>
        </span>
      </span>
      <br />
      <b-field class="oe">
        <div @click="favoriteRequest">
          <b-icon
            icon="heart"
            size="is-small"
            :class="individualRequest.isFavorite ? 'heatRed' : 'heatGray'"
            id="favorite"
          />
        </div>
        <span v-if="individualRequest.organization">
          {{ individualRequest.organization.name }} ({{
            individualRequest.organization.university.split("-")[0]
          }}
          - {{ individualRequest.organization.city }}/{{
            states[individualRequest.organization.state]
          }})
        </span>
        <span v-else>
          Prof. {{ individualRequest.professor.name }} ({{
            individualRequest.professor.university.split("-")[0]
          }}
          - {{ individualRequest.professor.city }}/{{
            states[individualRequest.professor.state]
          }})
        </span>
      </b-field>
    </div>

    <div class="block-content">
      <span class="title-content">Sobre a Solicitação:</span>
      <span
        >Data da Solicitação:
        {{ moment(individualRequest.createdAt, "DD/MM/YYYY") }}</span
      >
      <span>
        Formato:
        {{
          individualRequest.eventInfo.isOnline === false
            ? "Presencial"
            : "Online"
        }}
        {{ individualRequest.eventInfo.empowerSupport ? "com" : "sem" }}
        Mediação Empower</span
      >
      <span v-if="individualRequest.eventInfo.attendeeQty"
        >Público Estimado: {{ individualRequest.eventInfo.attendeeQty }}</span
      >
    </div>

    <div class="block-content" v-if="individualRequest.organization">
      <span class="title-content">Sobre a Organização Estudantil:</span>
      <span
        >Nome da OE: {{ individualRequest.organization.name }} ({{
          individualRequest.organization.category
        }})</span
      >
      <span
        >Universidade: {{ individualRequest.organization.university }} ({{
          individualRequest.organization.city
        }}
        - {{ states[individualRequest.organization.state] }})</span
      >
      <span id="courses">
        Cursos da OE:
        <span
          v-for="(curso, i) in individualRequest.organization.membersPerCourse"
          :key="i"
        >
          {{ curso.course }} ({{ curso.quantity }}){{
            individualRequest.organization.membersPerCourse[i + 1] ? ", " : "."
          }}
        </span>
      </span>
    </div>

    <div class="block-content" v-else>
      <span class="title-content">Sobre o/a Professor(a):</span>
      <span
        >Nome do(a) Professor(a): {{ individualRequest.professor.name }}</span
      >
      <span
        >Universidade: {{ individualRequest.professor.university }} ({{
          individualRequest.professor.city
        }}
        - {{ states[individualRequest.professor.state] }})</span
      >
      <span
        id="courses"
        v-if="individualRequest.eventInfo.subject.courses.length > 0"
      >
        Cursos dos Estudantes:
        <span
          v-for="(curso, i) in individualRequest.eventInfo.subject.courses"
          :key="i"
        >
          {{ curso
          }}{{
            individualRequest.eventInfo.subject.courses[i + 1] ? ", " : "."
          }}
        </span>
      </span>
      <br />
      <span
        id="courses"
        v-if="individualRequest.eventInfo.subject.semester.length > 0"
      >
        Semestres dos Estudantes:
        <span
          v-for="(semester, i) in individualRequest.eventInfo.subject.semester"
          :key="i"
        >
          {{ semester }}°{{
            individualRequest.eventInfo.subject.semester[i + 1]
              ? ", "
              : " Período"
          }}
        </span>
      </span>
      <br />
      <span id="courses" v-if="individualRequest.eventInfo.subject.subjectName">
        Nome da Disciplina:
        {{ individualRequest.eventInfo.subject.subjectName }}
      </span>
    </div>

    <div class="block-content">
      <span
        class="title-content"
        v-if="individualRequest.eventInfo.suggestedThemes.length > 0"
        >Sugestão de Temas:</span
      >
      <span
        v-for="(tema, i) in individualRequest.eventInfo.suggestedThemes"
        :key="i"
        id="courses"
      >
        {{ tema
        }}{{ individualRequest.eventInfo.suggestedThemes[i + 1] ? ", " : "." }}
      </span>
    </div>

    <div
      class="block-content"
      v-if="individualRequest.eventInfo.themesDescription"
    >
      <span class="title-content">Descrição dos Temas:</span>
      <span>{{ individualRequest.eventInfo.themesDescription }}</span>
    </div>

    <div
      class="block-content"
      v-if="individualRequest.requestType === 'project'"
    >
      <span class="title-content">Categoria da Parceria:</span>
      <span class="hour">
        <span
          v-for="(categoria, i) in individualRequest.eventInfo.projectCategory"
          :key="i"
        >
          {{ categoria
          }}{{
            individualRequest.eventInfo.projectCategory[i + 1] ? ", " : "."
          }}
        </span>
      </span>
    </div>

    <div class="block-content" v-if="individualRequest.eventInfo.eventName">
      <span class="title-content"
        >Nome do
        {{
          individualRequest.requestType == "lecture" ? "Evento" : "Projeto"
        }}:</span
      >
      <span>{{ individualRequest.eventInfo.eventName }}</span>
    </div>

    <div
      class="block-content"
      v-if="individualRequest.eventInfo.eventDescription"
    >
      <span
        class="title-content"
        v-if="individualRequest.requestType === 'project'"
        >Descrição do Projeto:</span
      >
      <span
        class="title-content"
        v-else-if="individualRequest.requestType === 'other'"
        >Descrição da Ação Solicitada:</span
      >
      <span class="title-content" v-else>Descrição dos Temas:</span>
      <span>{{ individualRequest.eventInfo.eventDescription }}</span>
    </div>

    <div
      class="block-content"
      v-if="individualRequest.eventInfo.supportMaterialURL"
    >
      <span class="title-content">Material de Suporte da OE:</span>
      <a :href="individualRequest.eventInfo.supportMaterialURL" target="_blank"
        >Link</a
      >
    </div>

    <div
      class="block-content"
      v-if="individualRequest.requestType === 'mentoring'"
    >
      <span class="title-content"
        >Quais as 3 principais dúvidas a serem respondidas na mentoria?</span
      >
      <span>Pergunta 1: {{ individualRequest.eventInfo.question1 }}</span>
      <span>Pergunta 2: {{ individualRequest.eventInfo.question2 }}</span>
      <span>Pergunta 3: {{ individualRequest.eventInfo.question3 }}</span>
    </div>

    <div class="block-content" v-if="individualRequest.requestType === 'visit'">
      <span class="title-content">Planta da Visita:</span>
      <span>{{ individualRequest.eventInfo.plant }}</span>
    </div>

    <div
      class="block-content"
      v-if="
        requestStatusForBusiness(individualRequest.requestStatus) != 'Realizada'
      "
    >
      <span class="title-content">Datas / Horários</span>
      <div class="schedules">
        <div
          class="schedule"
          v-if="individualRequest.eventInfo.dateOptions.first.start"
        >
          <b-field>
            <p class="control">
              <span class="button is-static">1ª opção</span>
            </p>
            <span class="button" disabled>{{
              moment(
                individualRequest.eventInfo.dateOptions.first.start,
                "DD/MM/YYYY"
              )
            }}</span>
          </b-field>
          <span
            class="button is-static hour"
            v-if="
              individualRequest.requestType != 'other' &&
              individualRequest.requestType != 'project' &&
              individualRequest.requestType != 'sponsorship'
            "
          >
            <b-icon icon="clock" size="is-small" />
            {{
              moment(
                individualRequest.eventInfo.dateOptions.first.start,
                "HH:mm"
              )
            }}
            às
            {{
              moment(individualRequest.eventInfo.dateOptions.first.end, "HH:mm")
            }}
          </span>
        </div>
        <div
          class="schedule"
          v-if="individualRequest.eventInfo.dateOptions.second.start"
        >
          <b-field>
            <p class="control">
              <span class="button is-static">2ª opção</span>
            </p>
            <span class="button" disabled>{{
              moment(
                individualRequest.eventInfo.dateOptions.second.start,
                "DD/MM/YYYY"
              )
            }}</span>
          </b-field>
          <span
            class="button is-static hour"
            v-if="
              individualRequest.requestType != 'other' &&
              individualRequest.requestType != 'project' &&
              individualRequest.requestType != 'sponsorship'
            "
          >
            <b-icon icon="clock" size="is-small" />
            {{
              moment(
                individualRequest.eventInfo.dateOptions.second.start,
                "HH:mm"
              )
            }}
            às
            {{
              moment(
                individualRequest.eventInfo.dateOptions.second.end,
                "HH:mm"
              )
            }}
          </span>
        </div>
        <div
          class="schedule"
          v-if="individualRequest.eventInfo.dateOptions.third.start"
        >
          <b-field>
            <p class="control">
              <span class="button is-static">3ª opção</span>
            </p>
            <span class="button" disabled>{{
              moment(
                individualRequest.eventInfo.dateOptions.third.start,
                "DD/MM/YYYY"
              )
            }}</span>
          </b-field>
          <span
            class="button is-static hour"
            v-if="
              individualRequest.requestType != 'other' &&
              individualRequest.requestType != 'project' &&
              individualRequest.requestType != 'sponsorship'
            "
          >
            <b-icon icon="clock" size="is-small" />
            {{
              moment(
                individualRequest.eventInfo.dateOptions.third.start,
                "HH:mm"
              )
            }}
            às
            {{
              moment(individualRequest.eventInfo.dateOptions.third.end, "HH:mm")
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="block-content">
      <span class="title-content">Definições para a Ação:</span>
      <span
        v-if="individualRequest.eventInfo.dateOptions.agreed.start"
        class="hour"
      >
        Data da Ação:
        {{
          moment(
            individualRequest.eventInfo.dateOptions.agreed.start,
            "DD/MM/YYYY"
          )
        }}
        <span
          v-if="
            individualRequest.requestType != 'other' &&
            individualRequest.requestType != 'project' &&
            individualRequest.requestType != 'sponsorship'
          "
        >
          às
          {{
            moment(
              individualRequest.eventInfo.dateOptions.agreed.start,
              "HH:mm"
            )
          }}
          -
          {{
            moment(individualRequest.eventInfo.dateOptions.agreed.end, "HH:mm")
          }}
        </span>
      </span>
      <span v-else>Data da Ação: (a definir)</span>
      <span v-if="individualRequest.eventInfo.title"
        >Tema da Ação: {{ individualRequest.eventInfo.title }}</span
      >
      <span v-else>Tema da Ação: (a definir)</span>
      <span v-if="individualRequest.eventInfo.speaker"
        >Palestrante:
        {{ individualRequest.eventInfo.speaker || "(a definir)" }}, Celular:
        {{ individualRequest.eventInfo.speakerCellphone || "(a definir)" }},
        E-mail:
        {{ individualRequest.eventInfo.speakerEmail || "(a definir)" }}</span
      >
    </div>

    <div
      class="block-content"
      v-if="
        requestStatusForBusiness(individualRequest.requestStatus) ===
        'Realizada'
      "
    >
      <span class="title-content">Pós-Ação</span>
      <span v-if="individualRequest.eventInfo.realAttendeeQty"
        >Público Real:
        {{ individualRequest.eventInfo.realAttendeeQty }} Participantes</span
      >
      <span v-if="individualRequest.socialMediaInteractions"
        >Seguidores da OE Impactados:
        {{ individualRequest.socialMediaInteractions }} Seguidores</span
      >
    </div>

    <div v-if="individualRequest.requestStatus == 'underEvaluation'">
      <b-tooltip
        label="Sua solicitação foi enviada para a Empower!"
        :active="!canYouAnswer"
      >
        <b-button
          class="feedback aceitar"
          @click="acceptActionModalActive = true"
          icon-left="check"
          rounded
          :disabled="!canYouAnswer"
          >Aceitar Ação</b-button
        >
        <b-button
          class="feedback recusar"
          @click="refuseActionModalActive = true"
          icon-left="times"
          rounded
          :disabled="!canYouAnswer"
          >Recusar Ação</b-button
        >
      </b-tooltip>
    </div>

    <div v-if="individualRequest.requestStatus == 'confirmed'">
      <b-button
        icon-left="edit"
        rounded
        class="feedback update"
        @click="acceptActionModalActive = true"
        >Editar</b-button
      >
    </div>

    <div class="block-content">
      <span class="title-content">Comentários sobre a Ação</span>
      <br />
      <b-field :label="opportunityName" label-position="on-border">
        <b-input expanded v-model="newComment"></b-input>
        <b-button @click="addComment">+</b-button>
      </b-field>
    </div>
    <div class="block-content" v-if="individualRequest.businessComments">
      <span v-if="individualRequest.businessComments.length > 0"
        >Comentários da Empresa:</span
      >
      <span v-for="(comment, i) in individualRequest.businessComments" :key="i">
        {{ comment.admin }} | {{ moment(comment.date, "DD/MM/YYYY") }} -
        {{ comment.comment }}
      </span>
    </div>

    <div
      class="block-content"
      v-if="individualRequest.adminCommentsForBusiness"
    >
      <span v-if="individualRequest.adminCommentsForBusiness.length > 0"
        >Comentários Empower:</span
      >
      <span
        v-for="(comment, i) in individualRequest.adminCommentsForBusiness"
        :key="i"
      >
        {{ comment.admin }} | {{ moment(comment.date, "DD/MM/YYYY") }} -
        {{ comment.comment }}
      </span>
    </div>

    <b-modal :active="acceptActionModalActive" :width="800" :can-cancel="false">
      <AcceptAction :request="individualRequest" />
    </b-modal>

    <b-modal :active="refuseActionModalActive" :width="600" :can-cancel="false">
      <RefuseAction :request="individualRequest" />
    </b-modal>

    <b-modal :active="updateSentModalActive" :width="600" :can-cancel="false">
      <UpdateSent />
    </b-modal>
  </div>
</template>

<script>
import { request } from "../../mixins/request";
import { statesNamesToAbbreviation } from "../../assets/js/states-name-to-abbreviation";
import { momentMixin } from "../../mixins/moment";
import AcceptAction from "./AcceptAction";
import RefuseAction from "./RefuseAction";
import UpdateSent from "../commom/UpdateSent";
import { mapGetters } from "vuex";
export default {
  name: "RequestModal",
  props: ["individualRequest", "opportunityName"],
  mixins: [request, momentMixin],
  components: {
    AcceptAction,
    RefuseAction,
    UpdateSent,
  },
  data() {
    return {
      acceptActionModalActive: false,
      refuseActionModalActive: false,
      updateSentModalActive: false,
      newComment: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
    states() {
      return statesNamesToAbbreviation;
    },
    canYouAnswer() {
      if (
        this.individualRequest.confirmedViaBusiness === undefined &&
        this.individualRequest.noAvailabilityViaBusiness === undefined
      ) {
        return true;
      } else if (
        this.individualRequest.confirmedViaBusiness ||
        this.individualRequest.noAvailabilityViaBusiness
      ) {
        return false;
      } else return true;
    },
  },
  methods: {
    favoriteRequest() {
      let data = {
        token: this.token,
        requestId: this.individualRequest._id,
        isFavorite: !this.individualRequest.isFavorite,
      };
      let message = "";
      if (this.individualRequest.isFavorite === true) {
        message = "Solicitação desafavoritada";
      } else {
        message = "Solicitação favoritada";
      }
      this.$store
        .dispatch("favoriteRequest", data)
        .then((res) => {
          this.$buefy.toast.open({
            message: message,
            type: "is-success",
          });
          return res;
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: "Houve um erro, tente novamente mais tarde.",
            type: "is-danger",
          });
          return e;
        });
    },
    addComment() {
      if (this.newComment != "") {
        let data = {
          token: this.token,
          requestId: this.individualRequest._id,
          comment: {
            admin: "Empresa",
            date: new Date(),
            comment: this.newComment,
          },
        };
        this.$store
          .dispatch("addComment", data)
          .then((res) => {
            this.$buefy.toast.open({
              message: "Comentário registrado no histórico ✅",
              type: "is-success",
            });
            return res;
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: "Houve um erro, tente novamente mais tarde.",
              type: "is-danger",
            });
            return e;
          });
      } else {
        this.$buefy.toast.open({
          message:
            "É necessário que o campo possua algum conteúdo. Tente novamente.",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.request-modal {
  padding: 2rem;
}

.status-ball {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  display: inline-block;
  margin-top: 0.4rem;
  margin-bottom: -0.2rem;
}

.ok {
  background-color: #33c7a7;
}

.expired {
  background-color: #fe7687;
}

.delayed {
  background-color: #ffd459;
}

.oe {
  font-weight: bold;
  font-size: 1.3rem;
}

.header {
  padding-bottom: 1rem;
  border-bottom: solid 1px #dbdbdb;
}

.block-content {
  margin-top: 1rem;
  display: block;
}

.block-content span {
  display: block;
}

.title-content {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

#courses,
#courses span {
  display: inline;
}

.feedback {
  margin-right: 1rem;
  margin-top: 1rem;
  color: white;
  padding: 1.2rem;
  font-weight: bold;
}

.aceitar {
  background-color: #33c7a7;
}
.aceitar[disabled] {
  background-color: #33c7a7;
}

.recusar {
  background-color: #fe5469;
}
.recusar[disabled] {
  background-color: #fe5469;
}
.update {
  background-color: #959595;
}

.schedules {
  display: flex;
  gap: 1.5rem;
}

.hour {
  display: flex !important;
  gap: 0.5rem;
}

.closeModal {
  border: none;
  display: block;
  margin-left: auto;
  margin-right: -1.8rem;
  margin-top: 0;
}

.closeModal:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
}

.feedback-modal {
  background-color: white;
  padding: 2rem;
  padding-top: 0;
  border: solid 1px gray;
}

#favorite svg {
  height: 1rem !important;
}

.heatRed {
  color: #fd7a7a;
}

.heatGray {
  color: #b5b5b5;
}

#favorite {
  margin-top: 0.1rem;
  margin-right: 0.8rem;
  display: block;
  cursor: pointer;
}
</style>