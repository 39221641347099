import Vue from "vue";
import Router from "vue-router";

import Panel from "./views/Panel";
import Dashboard from "./views/Dashboard";
import Requests from "./views/Requests";
import OpportunityPage from "./views/OpportunityPage";
import OpportunityWebinars from "./views/OpportunityWebinars";
import MeetingMinutes from "./views/MeetingMinutes";
import Report from "./views/Report";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: Panel,
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard
        },
        {
          path: "requests",
          name: "requests",
          component: Requests
        },
        {
          path: "opportunity-page",
          name: "opportunityPage",
          component: OpportunityPage
        },
        {
          path: "opportunity-webinars",
          name: "opportunityWebinars",
          component: OpportunityWebinars
        },
        {
          path: "meeting-minutes",
          name: "meetingMinutes",
          component: MeetingMinutes
        },
        {
          path: "report",
          name: "report",
          component: Report
        }
      ]
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.afterEach(to => {
  if (process.env.VUE_APP_USE_GA) {
    // eslint-disable-next-line no-undef
    gtag("config", process.env.VUE_APP_GA_TRACKING_ID, {
      page_path: to.fullPath,
      app_name: "Business",
      send_page_view: true
    });
  }
});

export default router;
