import moment from "moment";

const momentMixin = {
  methods: {
    moment(date, format) {
      return format ? moment(date).format(format) : moment(date);
    }
  }
};

export { momentMixin };
