export const weeklyEvolution = {
  // O método constructor recebe a data inicial e final do projeto, o array de solicitações e número de semanas
  // que serão exibidas no gráfico.
  constructor(initial_date, final_date, requests, number_of_weeks) {
    // 1. O método projectWeeks recebe a data inicial e final do projeto e retorna um array com todas as semanas
    // do projeto.
    var project_in_weeks = this.projectWeeks(new Date(initial_date), new Date(final_date))
    // 2. Aqui percorremos cada semana do projeto.
    for (let i = 0; i < project_in_weeks.length; i++) {
      // 2.1. Dentro de cada semana, percorremos todas as solicitações.
      for (let j = 0; j < requests.length; j++) {
        // 2.1.1. Aqui fazemos uma verificação: se a data de criação da solicitação for menor que a data do último dia
        // da semana, essa semana incrementa mais um número de solicitações.
        if ((new Date(requests[j].createdAt).getTime()) <= project_in_weeks[i].last_day_in_milliseconds) {
          project_in_weeks[i].number_of_requests++
        }
      }
    }
    // 3. Essa variável pega a data atual da máquina.
    var current_date = new Date();
    var current_week = null;
    for(let i = 0; i < project_in_weeks.length; i++){
      // 4. Aqui procuramos em qual das semanas do projeto estamos. Para isso, é feito a seguinte verificação:
      // Se a data atual for maior que a data do primeiro dia da semana, a semana em questão vai para a 
      // variável current_week. Como o laço percorre todas as semanas do projeto, essa lógica funcionará até mesmo
      // se o projeto já tiver sido finalizado.
      if(current_date.getTime() > project_in_weeks[i].first_day_in_milliseconds){
       current_week = project_in_weeks[i]
      }
    }
    // 5. Se o projeto ainda não tiver mais de 12 semanas, mostramos todas as semanas que já temos até então.
    if(current_week.index <= number_of_weeks){
      // return this.formatData(project_in_weeks.slice(0, current_week.index - 1))
      return this.formatData(project_in_weeks.slice(0, current_week.index))
    }
    // 6. Se tivermos mais de 12 semanas, mostramos as últimas 12 semanas do projeto.
    else{
      return this.formatData(project_in_weeks.slice(current_week.index - number_of_weeks, current_week.index))
    }
  },
  // Esse método recebe uma data inicial e final, verifica quantas semanas existe dentro desse intervalo,
  // instancia uma primeira semana no array, e depois completa o array com as outras semanas até a data final
  // e retorna esse array.
  projectWeeks(initial_date, final_date) {
    var project_weeks = new Array()
    var number_of_weeks = this.convertToWeeks(initial_date, final_date);

    project_weeks.push({
      first_day: initial_date,
      last_day: new Date(initial_date.getFullYear(), initial_date.getMonth(), initial_date.getDate() + 6),
      first_day_in_milliseconds: initial_date.getTime(),
      last_day_in_milliseconds: new Date(initial_date.getFullYear(), initial_date.getMonth(), initial_date.getDate() + 6).getTime(),
      index: 1,
      number_of_requests: 0
    })

    for (let i = 1; i <= number_of_weeks; i++) {
      project_weeks.push({
        first_day: new Date(project_weeks[i - 1].last_day.getFullYear(), project_weeks[i - 1].last_day.getMonth(), (project_weeks[i - 1].last_day.getDate() + 1)),
        last_day: new Date(project_weeks[i - 1].last_day.getFullYear(), project_weeks[i - 1].last_day.getMonth(), project_weeks[i - 1].last_day.getDate() + 7),
        first_day_in_milliseconds: new Date(project_weeks[i - 1].last_day.getFullYear(), project_weeks[i - 1].last_day.getMonth(), (project_weeks[i - 1].last_day.getDate() + 1)).getTime(),
        last_day_in_milliseconds: new Date(project_weeks[i - 1].last_day.getFullYear(), project_weeks[i - 1].last_day.getMonth(), project_weeks[i - 1].last_day.getDate() + 7).getTime(),
        index: i + 1,
        number_of_requests: 0
      })
    }

    return project_weeks
  },
  // Esse método calcula quantas semanas existe dentro de um intervalo de datas e arredonda o número para um valor inteiro.
  convertToWeeks(initial_date, final_date) {
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;

    var difference = final_date.getTime() - initial_date.getTime();
    return new Number(((difference / day) / 7).toFixed(0));
  },
  // Esse método formata o array das semanas do projeto para a estrutura de dados (label e data) utilizada no chart.
  formatData(project_in_weeks){
    var labels = new Array()
    var data = new Array()
    for(let i = 0; i<project_in_weeks.length; i++){
      labels.push(
        "Semana "+(project_in_weeks[i].index < 10 ? '0' : '')+""+project_in_weeks[i].index
      )
      data.push(
        project_in_weeks[i].number_of_requests
      )
    }
    return {labels, data}
  }
}