<template>
  <b-navbar fixed-top>
    <template slot="brand">
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/' }"
        class="gray-blackground"
      >
        <a href="https://empower.vision">
          <figure class="image">
            <img
              id="logo-navbar"
              src="../../assets/img/logo-empower-branco-verde.png"
              width="170"
              height="22"
              alt="Logo Empower | Employer Branding e Recrutamento"
              title="Empower - Employer Branding e Recrutamento"
            />
          </figure>
        </a>
      </b-navbar-item>
      <figure class="image">
        <img :src="businessLogoURL" id="logo-business" v-if="businessLogoURL" />
      </figure>
    </template>

    <template slot="end">

      <b-button class="mailing-button" @click="getMailing" icon-left="download" v-if="$route.name == 'dashboard' || $route.name == 'requests'">
        <span class="mailing-text">Mailing</span>
      </b-button>

      <b-navbar-item tag="p" v-if="$route.name == 'dashboard' || $route.name == 'requests'">
        <div v-if="opportunity.contracts">
          <b-select
            placeholder="Selecione um plano"
            v-model="plan"
            class="select-contract"
            @input="changePlan"
          >
            <option :value="-1">Todos os Planos</option>
            <option
              v-for="(contract, i) in opportunity.contracts"
              :value="i"
              :key="i"
            >
              <p>
                Plano
                {{
                  contract.plan === "tasting"
                    ? "Degustação"
                    : contract.plan === "3months"
                    ? "3 meses"
                    : contract.plan === "semiannual"
                    ? "6 meses"
                    : contract.plan === "9months"
                    ? "9 meses"
                    : contract.plan === "annual"
                    ? "12 meses"
                    : "-"
                }}
                <br />
                {{
                  `${moment(contract.dates[0]).format("DD/MM/YY")} - ${moment(
                    contract.dates[1]
                  ).format("DD/MM/YY")}`
                }}
              </p>
            </option>
          </b-select>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import { momentMixin } from "../../mixins/moment";

export default {
  mixins: [momentMixin],
  data() {
    return {
      plan: -1,
    };
  },
  methods: {
    changePlan() {
      this.$store.dispatch(
        "setContract",
        this.opportunity.contracts[this.plan]
      );
    },
    getMailing(){
      window.location.href = this.opportunity.consolidatedMailing;
    }
  },
  computed: {
    ...mapGetters(["opportunity", "contract"]),
    businessLogoURL() {
      try {
        return this.opportunity.logoForBusiness;
      } catch {
        return null;
      }
    },
  },
};
</script>

<style scoped>
#navbar {
  z-index: 890;
}

.gray-blackground {
  background-color: #404040 !important;
}

.mailing-button {
  width: 105px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #6b6b6b;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: .5rem;
}

.mailing-text {
  font-family: "Nirmala UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #6b6b6b;
}

#logo-navbar {
  margin-left: 1.55rem;
  margin-right: 2.45rem;
  width: 9.5rem !important;
  cursor: pointer;
}

#logo-business {
  width: 4rem;
}

.card-header {
  box-shadow: none !important;
}

.select-contract {
  border: none;
}

@media (max-width: 425px) {
  #logo-navbar {
    margin-left: 25px;
  }
}
</style>
