<template>
  <section>
    <b-menu :activable="false">
      <b-menu-list>
        <b-menu-item
          label="Dashboard"
          tag="router-link"
          to="/dashboard"
          :active="$route.name == 'dashboard'"
        ></b-menu-item>
        <hr />
        <b-menu-item
          label="Solicitações"
          tag="router-link"
          to="/requests"
          :active="$route.name == 'requests'"
        ></b-menu-item>
        <hr />
        <b-menu-item
          label="Página da Empresa"
          tag="router-link"
          to="/opportunity-page"
          :active="$route.name == 'opportunityPage'"
        ></b-menu-item>
        <hr />
        <b-menu-item
          label="Webinars da Empresa"
          tag="router-link"
          to="/opportunity-webinars"
          :active="$route.name == 'opportunityWebinars'"
        ></b-menu-item>
        <hr />
        <b-menu-item
          label="Atas de Reuniões"
          tag="router-link"
          to="/meeting-minutes"
          :active="$route.name == 'meetingMinutes'"
        ></b-menu-item>
        <hr />
        <b-menu-item :active="socialMediaItemIsActive">
          <template #label="props">
            Redes Sociais Empower
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'angle-up' : 'angle-down'"
            ></b-icon>
          </template>
          <b-menu-item
            label="Site"
            tag="a"
            href="https://empower.vision/"
            target="_blank"
          ></b-menu-item>
          <hr />
          <b-menu-item
            label="Instagram"
            tag="a"
            href="https://www.instagram.com/empower.oficial/"
            target="_blank"
          ></b-menu-item>
          <hr />
          <b-menu-item
            label="Youtube"
            tag="a"
            href="https://www.youtube.com/c/EmpowerOficial"
            target="_blank"
          ></b-menu-item>
          <hr />
          <b-menu-item
            label="Facebook"
            tag="a"
            href="https://www.facebook.com/empower.oficial"
            target="_blank"
          ></b-menu-item>
          <hr />
          <b-menu-item
            label="Linkedin"
            tag="a"
            href="https://www.linkedin.com/company/empower-oficial/mycompany/"
            target="_blank"
          ></b-menu-item>
        </b-menu-item>
        <hr />
        <b-menu-item
          label="Contato Empower"
          @click="$parent.contactModalIsActive = true"
        ></b-menu-item>
        <hr />
      </b-menu-list>
    </b-menu>
  </section>
</template>
<script>
export default {
  name: "LateralMenu",
  data() {
    return {
      socialMediaItemIsActive: false
    };
  },
};
</script>
<style scoped>
section {
  width: 15rem;
  height: 100%;
  background-color: #404040;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}
.menu {
  background-color: #404040;
}
.menu-list li ul {
  margin: 0 !important;
}
hr {
  margin: 0;
  background-color: #363636;
  height: 1px;
}
</style>