<template>
  <section>
      <h2>Distribuição por Tipo de Ação</h2>
    <div>
      <TypesTable :requests="requests" />
    </div>
  </section>
</template>

<script>
import TypesTable from "./charts/TypesTable"
export default {
  name: "DistributionByType",
  props: ["requests"],
  components: {
    TypesTable
  }
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
}
h2 {
  font-size: 20px;
  color: #404040;
  font-weight: 700;
  margin-right: auto;
}
.chart {
  max-width: 32rem;
  display: block;
  margin: auto;
}
</style>