<template>
  <section>
    <Cover :coverImageURL="opportunity.page.coverImageURL" v-if="opportunity.page.coverImageURL" />

    <Logo :businessLogoURL="opportunity.page.businessLogoURL" />

    <company-name
      :businessName="opportunity.businessName"
      :treatmentPronoun="opportunity.treatmentPronoun"
      :fanpage="opportunity.fanpage"
      :insta="opportunity.insta"
      :linkedin="opportunity.linkedin"
      :site="opportunity.site"
    />

    <info-bottom
      v-if="opportunity.page.content0"
      :content="opportunity.page.content0"
    />

    <info1
      :contentImageURL="opportunity.page.contentImage1URL"
      :content="opportunity.page.content1"
    />

    <info2
      :contentImageURL="opportunity.page.contentImage2URL"
      :content="opportunity.page.content2"
    />

    <info-bottom
      v-if="opportunity.page.content3"
      :content="opportunity.page.content3"
    />

    <carousel
      v-if="
        opportunity.page.carouselImages &&
          opportunity.page.carouselImages.length
      "
      :carouselImages="opportunity.page.carouselImages"
    />

    <info-bottom
      v-if="opportunity.page.content4"
      :content="opportunity.page.content4"
    />
  </section>
</template>

<script>
import Cover from '../components/opportunity-page/Cover'
import Logo from '../components/opportunity-page/Logo'
import CompanyName from '../components/opportunity-page/CompanyName'
import InfoBottom from '../components/opportunity-page/InfoBottom'
import Info1 from '../components/opportunity-page/Info1'
import Info2 from '../components/opportunity-page/Info2'
import Carousel from '../components/opportunity-page/Carousel'

import { mapGetters } from "vuex";
export default {
  name: "OpportunityPage",
  components: {
    Cover,
    Logo,
    CompanyName,
    InfoBottom,
    Info1,
    Info2,
    Carousel
  },
  computed: {
    ...mapGetters(["opportunity"]),
  }
}
</script>

<style scoped>
section{
  padding-bottom: 4rem;
}

@media screen and (min-width: 1408px){
  .container{
    max-width: 90%;
  }
}

@media screen and (min-width: 1216px){
  .container{
    max-width: 90%;
  }
}

@media screen and (min-width: 1024px){
  .container{
    max-width: 80%;
  }
}
</style>