const responsive = {
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },

  computed: {
    isMobile() {
      return this.windowWidth < 769;
    },
    isTablet() {
      return this.windowWidth > 768 && this.windowWidth < 1024;
    },
    isDesktop() {
      return this.windowWidth > 1023;
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
  }
};

export { responsive };
