<template>
  <section>
    <div v-if="calendar">
      <b-carousel
        :autoplay="false"
        :indicator="false"
        :arrow="true"
        :iconPrev="'arrow-left'"
        :iconNext="'arrow-right'"
        :arrowBoth="false"
        :arrow-hover="false"
        v-model="atualMonth"
      >
        <b-carousel-item v-for="(period, i) in calendar" :key="i">
          <div>
            <h2>{{ period.month }}/{{ period.year }}</h2>
            <div class="table-headers">
              <div class="table-header">
                <span>Dia</span>
                <span>Ações Confirmadas</span>
              </div>
              <div class="table-header">
                <span>Dia</span>
                <span>Ações Confirmadas</span>
              </div>
            </div>
            <div class="fortnight">
              <div
                class="table-line"
                :class="day.day"
                v-for="(day, i) in period.days"
                :key="i"
              >
                <span>{{ day.date }}</span>
                <span>{{ day.day }}</span>
                <div style="display: flex; margin-left: 1rem">
                  <span v-for="(event, i) in day.events" :key="i">
                    <span
                      :class="event.requestType"
                      class="action-item"
                      @click="$parent.$parent.showRequest(event)"
                    >
                      {{ requestTypeText(event.requestType, 0) }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </b-carousel-item>
      </b-carousel>
    </div>
  </section>
</template>

<script>
import { calendar } from "../confirmed-actions/calendar-generator/CalendarGenerator.js";
import { mapGetters } from "vuex";
import { request } from "../../../mixins/request.js";
export default {
  name: "CalendarCarousel",
  mixins: [request],
  props: ["requests"],
  computed: {
    ...mapGetters(["opportunity", "contract"]),
    calendar() {
      if (this.requests) {
        if (this.contract) {
          return calendar.constructor(
            this.contract.dates[0],
            this.contract.dates[1],
            this.requests
          );
        } else if (this.opportunity.contracts) {
          return calendar.constructor(
            this.opportunity.contracts[this.opportunity.contracts.length - 1]
              .dates[0],
            this.opportunity.contracts[this.opportunity.contracts.length - 1]
              .dates[1],
            this.requests
          );
        } else return null;
      } else return null;
    },
    atualMonth() {
      let today = new Date();
      for (let i = 0; i < this.calendar.length; i++) {
        if (today.getFullYear() === this.calendar[i].year) {
          if (today.getMonth() === this.calendar[i].monthIndex) {
            return i;
          }
        }
      }
      return 0;
    },
  },
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem;
  display: block;
}
.carousel {
  margin: 1rem;
}
h2 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
}
.table-headers {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 0.7rem;
}
.table-header {
  display: flex;
  background-color: #ededed;
  border-bottom: solid 0.5px #959595;
  height: 2rem;
}
.table-header span {
  font-weight: bold;
  color: #444444;
  margin-left: 2.2rem;
  margin-top: 0.3rem;
}
.table-line {
  border-bottom: solid 0.5px #959595;
  display: grid;
  grid-template-columns: 2rem 2rem auto;
  height: 1.7rem;
  padding-left: 1rem;
}

.fortnight {
  margin-top: 0.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 49% 49%;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  gap: 0.7rem;
}

.action-item {
  width: 10rem;
  display: block;
  text-align: center;
  padding: 0.2rem 0rem;
  margin: 0.2rem 0.5rem;
  margin-top: -0.5rem;
  cursor: pointer;
}
.webinar {
  background-color: #66d5bd;
  color: white;
}
.training {
  background-color: #00b991;
  color: white;
}
.visit {
  background-color: #ccf1e9;
  color: black;
}
.lecture {
  background-color: #99a8f0;
  color: black;
}
.mentoring {
  background-color: #556ee6;
  color: white;
}
.sponsorship {
  background-color: #a6a6a6;
  color: black;
}
.project {
  background-color: #dde2fa;
  color: black;
}
.other {
  background-color: #cacaca;
  color: black;
}
.lesson {
  background-color: #ededed;
  color: black;
}
.otherProf {
  background-color: #e7c7f3;
  color: black;
}
.research {
  background-color: #b045d8;
  color: white;
}
.visitProf {
  background-color: #c87de4;
  color: black;
}

.closeModal {
  border: none;
  display: block;
  margin-left: auto;
  margin-right: 0.2rem;
  margin-top: 0;
}

.closeModal:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
}
.sab,
.dom {
  background-image: linear-gradient(transparent, #dddddd);
}

.carousel-arrow .icon {
  border: solid 1px black !important;
}
</style>