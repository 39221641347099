import { statesNamesToAbbreviation } from "../../../../assets/js/states-name-to-abbreviation";
export const filter = {
  generalRequests(requests) {
    var generalRequestsData = {
      number_of_actions_requested: 0,
      esteemed_students: 0,
      performed: {
        number_of_requests: 0,
        percentage_of_requests: 0,
        number_of_students: 0,
        percentage_of_students: 0
      },
      confirmed: {
        number_of_requests: 0,
        percentage_of_requests: 0,
        number_of_students: 0,
        percentage_of_students: 0
      },
      under_evaluation: {
        number_of_requests: 0,
        percentage_of_requests: 0,
        number_of_students: 0,
        percentage_of_students: 0
      },
      no_availability: {
        number_of_requests: 0,
        percentage_of_requests: 0,
        number_of_students: 0,
        percentage_of_students: 0
      },
      canceled_by_the_OEs: {
        number_of_requests: 0,
        percentage_of_requests: 0,
        number_of_students: 0,
        percentage_of_students: 0
      },
      different_from_the_target_profile: {
        number_of_requests: 0,
        percentage_of_requests: 0,
        number_of_students: 0,
        percentage_of_students: 0
      }
    };
    generalRequestsData.number_of_actions_requested = requests.length;

    for (let i = 0; i < requests.length; i++) {
      if (requests[i].eventInfo.attendeeQty) {
        generalRequestsData.esteemed_students += requests[i].eventInfo.attendeeQty || 0;
      }
      if (requests[i].visibleStatus == 'Realizada') {
        generalRequestsData.performed.number_of_requests++;
        if (requests[i].eventInfo.attendeeQty) {
          generalRequestsData.performed.number_of_students += requests[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests[i].visibleStatus == 'Confirmada') {
        generalRequestsData.confirmed.number_of_requests++;
        if (requests[i].eventInfo.attendeeQty) {
          generalRequestsData.confirmed.number_of_students += requests[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests[i].visibleStatus == 'Em Avaliação') {
        generalRequestsData.under_evaluation.number_of_requests++;
        if (requests[i].eventInfo.attendeeQty) {
          generalRequestsData.under_evaluation.number_of_students += requests[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests[i].visibleStatus == 'Sem disponibilidade empresa') {
        generalRequestsData.no_availability.number_of_requests++;
        if (requests[i].eventInfo.attendeeQty) {
          generalRequestsData.no_availability.number_of_students += requests[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests[i].visibleStatus == 'Cancelado pela OE') {
        generalRequestsData.canceled_by_the_OEs.number_of_requests++;
        if (requests[i].eventInfo.attendeeQty) {
          generalRequestsData.canceled_by_the_OEs.number_of_students += requests[i].eventInfo.attendeeQty || 0;
        }
      }
      if (requests[i].visibleStatus == '---') {
        generalRequestsData.different_from_the_target_profile.number_of_requests++;
        if (requests[i].eventInfo.attendeeQty) {
          generalRequestsData.different_from_the_target_profile.number_of_students += requests[i].eventInfo.attendeeQty || 0;
        }
      }
    }
    generalRequestsData.performed.percentage_of_requests = ((100 * generalRequestsData.performed.number_of_requests) / generalRequestsData.number_of_actions_requested) || 0;
    generalRequestsData.performed.percentage_of_students = ((100 * generalRequestsData.performed.number_of_students) / generalRequestsData.esteemed_students) || 0;
    generalRequestsData.confirmed.percentage_of_requests = ((100 * generalRequestsData.confirmed.number_of_requests) / generalRequestsData.number_of_actions_requested) || 0;
    generalRequestsData.confirmed.percentage_of_students = ((100 * generalRequestsData.confirmed.number_of_students) / generalRequestsData.esteemed_students) || 0;
    generalRequestsData.under_evaluation.percentage_of_requests = ((100 * generalRequestsData.under_evaluation.number_of_requests) / generalRequestsData.number_of_actions_requested) || 0;
    generalRequestsData.under_evaluation.percentage_of_students = ((100 * generalRequestsData.under_evaluation.number_of_students) / generalRequestsData.esteemed_students) || 0;
    generalRequestsData.no_availability.percentage_of_requests = ((100 * generalRequestsData.no_availability.number_of_requests) / generalRequestsData.number_of_actions_requested) || 0;
    generalRequestsData.no_availability.percentage_of_students = ((100 * generalRequestsData.no_availability.number_of_students) / generalRequestsData.esteemed_students) || 0;
    generalRequestsData.canceled_by_the_OEs.percentage_of_requests = ((100 * generalRequestsData.canceled_by_the_OEs.number_of_requests) / generalRequestsData.number_of_actions_requested) || 0;
    generalRequestsData.canceled_by_the_OEs.percentage_of_students = ((100 * generalRequestsData.canceled_by_the_OEs.number_of_students) / generalRequestsData.esteemed_students) || 0;
    generalRequestsData.different_from_the_target_profile.percentage_of_requests = ((100 * generalRequestsData.different_from_the_target_profile.number_of_requests) / generalRequestsData.number_of_actions_requested) || 0;
    generalRequestsData.different_from_the_target_profile.percentage_of_students = ((100 * generalRequestsData.different_from_the_target_profile.number_of_students) / generalRequestsData.esteemed_students) || 0;

    return generalRequestsData;
  },

  distributionByState(requests) {
    var states = [];
    var requestsStates = [
      { state: "AC", rank: 0 },
      { state: "AL", rank: 0 },
      { state: "AM", rank: 0 },
      { state: "AP", rank: 0 },
      { state: "BA", rank: 0 },
      { state: "CE", rank: 0 },
      { state: "DF", rank: 0 },
      { state: "ES", rank: 0 },
      { state: "GO", rank: 0 },
      { state: "MA", rank: 0 },
      { state: "MG", rank: 0 },
      { state: "MS", rank: 0 },
      { state: "MT", rank: 0 },
      { state: "PA", rank: 0 },
      { state: "PB", rank: 0 },
      { state: "PE", rank: 0 },
      { state: "PI", rank: 0 },
      { state: "PR", rank: 0 },
      { state: "RJ", rank: 0 },
      { state: "RN", rank: 0 },
      { state: "RO", rank: 0 },
      { state: "RR", rank: 0 },
      { state: "RS", rank: 0 },
      { state: "SC", rank: 0 },
      { state: "SE", rank: 0 },
      { state: "SP", rank: 0 },
      { state: "TO", rank: 0 },
    ];
    for (let i = 0; i < requests.length; i++) {
      try {
        states.push(statesNamesToAbbreviation[requests[i].organization.state]);
      } catch {
        states.push(statesNamesToAbbreviation[requests[i].professor.state]);
      }
    }
    for (let i = 0; i < states.length; i++) {
      if (requestsStates.findIndex(state => state.state == states[i]) > 0) {
        requestsStates[requestsStates.findIndex(state => state.state == states[i])].rank++;
      }
    }
    return requestsStates
  },

  distribuitionByType(requests) {
    var data = [];
    var types = [];
    for(let i =0; i<requests.length; i++){
      types.push({
        type: requests[i].requestType
      })
    }
    let occurrence = this.findOccurrence(types, "type");
    
    for(let i = 0; i<occurrence.length; i++){
      if(occurrence[i].occurrence > 0){
        data.push({
          name: occurrence[i].type,
          quantity: occurrence[i].occurrence,
          percentage: (occurrence[i].occurrence * 100) / requests.length
        })
      }
    }

    return data
  },
  performedActions(requests) {
    var numbers = {
      performed_actions: 0,
      impacted_students: 0,
      interactions_in_posts: 0,
      followers_impacted: 0
    }
    for (let i = 0; i < requests.length; i++) {
      if (requests[i].visibleStatus == "Realizada") {
        numbers.performed_actions++;
        numbers.impacted_students += requests[i].eventInfo.attendeeQty || 0;
      }
    }

    return numbers
  },
  impactedUniversities(requests) {
    var universities = new Array();
    for (let i = 0; i < requests.length; i++) {
      try {
        universities.push({
          university: requests[i].organization.university
        })
      } catch {
        universities.push({
          university: requests[i].professor.university
        })
      }
    }
    return this.findOccurrence(universities, "university").sort((a, b) => b.occurrence - a.occurrence)
  },
  impactedCourses(requests) {
    var courses = new Array();
    for (let i = 0; i < requests.length; i++) {
      try {
        for (let j = 0; j < requests[i].organization.membersPerCourse.length; j++) {
          courses.push({
            course: requests[i].organization.membersPerCourse[j].course
          })
        }
      }
      catch {
        for(let j = 0; j < requests[i].eventInfo.subject.courses.length; j++){
          courses.push({
            course: requests[i].eventInfo.subject.courses[j]
          })
        }
      }
    }
    return this.findOccurrence(courses, "course").sort((a, b) => b.occurrence - a.occurrence)
  },
  requestedThemes(requests) {
    var themes = new Array();
    for (let i = 0; i < requests.length; i++) {
      for (let j = 0; j < requests[i].eventInfo.suggestedThemes.length; j++) {
        themes.push({
          theme: requests[i].eventInfo.suggestedThemes[j]
        })
      }
    }
    return this.findOccurrence(themes, "theme").sort((a, b) => b.occurrence - a.occurrence)
  },
  findOccurrence(arr, key) {
    let arr2 = new Array();
    arr.forEach((x) => {
      if (arr2.some((val) => { return val[key] == x[key] })) {
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"]++
          }
        })
      } else {
        let a = {}
        a[key] = x[key]
        a["occurrence"] = 1
        arr2.push(a)
      }
    })
    return arr2
  }
}