<template>
  <section>
    <div id="dashboard">
      <div class="dashboard-header">
        <h1>AÇÕES REALIZADAS</h1>
        <b-select placeholder="Tipo de Ação" class="action-type" v-model="type">
          <option value="">Todas as Ações</option>
          <option value="webinar">Webinars</option>
          <option value="lecture">Palestras</option>
          <option value="training">Treinamentos</option>
          <option value="visit">Visitas Técnicas</option>
          <option value="mentoring">Mentorias</option>
          <option value="project">Projetos</option>
          <option value="sponsorship">Patrocínios</option>
          <option value="other">Outras Ações</option>
        </b-select>
      </div>
      <hr />
      <div class="boxes">
        <div class="total-numbers">
          <data-box
            :number="performedActions.performed_actions"
            message="Ações Realizadas"
            color="#4458b8"
          />
          <data-box
            :number="performedActions.impacted_students"
            message="Estudantes Impactados <br> pelas Ações Realizadas"
            color="#4458b8"
          />
        </div>
        <div class="total-numbers">
          <data-box
            :number="performedActions.socialMediaFollowers"
            message="REDES SOCIAIS <br> Seguidores Impactados"
            color="#667de9"
          />
          <data-box
            :number="opportunity.newsletterReaders || 0"
            message="NEWSLETTERS <br> Leitores Impactados"
            color="#667de9"
          />
        </div>
        <distribution-by-type2 :requests="filteredRequests" />
        <satisfaction-survey :survey="opportunitySurvey" />
        <latest-actions-performed
          class="latest-actions"
          :requests="filteredRequests"
        />
      </div>

      <h1>AÇÕES CONFIRMADAS</h1>
      <hr />
      <div class="boxes">
        <general-requests2 :requests="filteredRequests" />
        <next-actions :requests="filteredRequests" />
      </div>
      <calendar-carousel :requests="filteredRequests" />
      <hr />
      <hr />

      <h1>AÇÕES SOLICITADAS</h1>
      <hr />
      <div class="boxes">
        <general-requests :requests="filteredRequests" />
        <distribution-by-state :requests="filteredRequests" />
        <distribution-by-type :requests="filteredRequests" />
        <weekly-evolution :requests="filteredRequests" />
      </div>
      <div class="boxes-three">
        <impacted-universities :requests="filteredRequests" />
        <impacted-courses :requests="filteredRequests" />
        <requested-themes :requests="filteredRequests" />
      </div>

      <h1>AÇÕES SEM DISPONIBILIDADE</h1>
      <hr />
      <div class="boxes">
        <general-requests3 :requests="filteredRequests" />
        <without-availability-actions :requests="filteredRequests" />
      </div>
    </div>
  </section>
</template>

<script>
import GeneralRequests from "../components/dashboard/requested-actions/GeneralRequests";
import DistributionByState from "../components/dashboard/requested-actions/DistributionByState";
import DistributionByType from "../components/dashboard/requested-actions/DistributionByType";
import WeeklyEvolution from "../components/dashboard/requested-actions/WeeklyEvolution";
import ImpactedUniversities from "../components/dashboard/requested-actions/ImpactedUniversities";
import ImpactedCourses from "../components/dashboard/requested-actions/ImpactedCourses";
import RequestedThemes from "../components/dashboard/requested-actions/RequestedThemes";
import DataBox from "../components/commom/DataBox";
import DistributionByType2 from "../components/dashboard/performed-actions/DistributionByType";
import LatestActionsPerformed from "../components/dashboard/performed-actions/LatestActionsPerformed";
import SatisfactionSurvey from "../components/dashboard/performed-actions/SatisfactionSurvey";
import GeneralRequests2 from "../components/dashboard/confirmed-actions/GeneralRequests";
import NextActions from "../components/dashboard/confirmed-actions/NextActions";
import CalendarCarousel from "../components/dashboard/confirmed-actions/CalendarCarousel";
import GeneralRequests3 from "../components/dashboard/without-availability-actions/GeneralRequests";
import WithoutAvailabilityActions from "../components/dashboard/without-availability-actions/WithoutAvailabilityActions";

import { filter } from "../components/dashboard/performed-actions/performed-actions-filter/performed-actions-filter";

import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    GeneralRequests,
    DistributionByState,
    DistributionByType,
    WeeklyEvolution,
    ImpactedUniversities,
    ImpactedCourses,
    RequestedThemes,
    DataBox,
    DistributionByType2,
    LatestActionsPerformed,
    SatisfactionSurvey,
    GeneralRequests2,
    NextActions,
    CalendarCarousel,
    GeneralRequests3,
    WithoutAvailabilityActions,
  },
  data() {
    return {
      type: "",
    };
  },
  computed: {
    ...mapGetters(["requests", "contract", "opportunity"]),
    performedActions() {
      if (this.filteredRequests) {
        return filter.performedActions(this.filteredRequests);
      } else {
        return null;
      }
    },
    filteredRequests() {
      if (this.type) {
        return this.planRequests.filter(
          (request) => request.requestType == this.type
        );
      } else return this.planRequests;
    },
    planRequests() {
      if (this.contract) {
        return this.requests.filter(
          (request) =>
            new Date(request.createdAt) > new Date(this.contract.dates[0]) &&
            new Date(request.createdAt) < new Date(this.contract.dates[1])
        );
      } else return this.requests;
    },
    opportunitySurvey() {
      return this.opportunity.survey;
    },
  },
};
</script>

<style scoped>
#dashboard {
  margin: 2rem;
  margin-right: 0.8rem;
}
.boxes {
  display: grid;
  grid-template-columns: 49% 49.8%;
  gap: 1rem;
  margin-bottom: 2rem;
}
.boxes-three {
  display: grid;
  grid-template-columns: 32.4% 32.4% 32.4%;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: -1rem;
}
h1 {
  color: #404040;
  font-weight: bold;
  font-size: 24px;
}
.dashboard-header {
  display: flex;
}
.action-type {
  margin-left: auto;
}
hr {
  background-color: transparent;
  height: 1px;
  margin: 0.5rem 0;
}
.total-numbers {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}

.latest-actions {
  grid-column-start: 1;
  grid-column-end: 3;
}

/* @media (max-width: 1300px) {
  .boxes {
    grid-template-columns: auto;
  }
} */
@media (max-width: 1420px) {
  #dashboard {
    transform: scale(0.9);
    transform-origin: 0 0;
  }
}
@media (max-width: 1300px) {
  #dashboard {
    transform: scale(0.85);
    transform-origin: 0 0;
  }
}
@media (max-width: 1240px) {
  #dashboard {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}
@media (max-width: 1210px) {
  #dashboard {
    transform: scale(0.85);
    transform-origin: 0 0;
  }
  .boxes,
  .boxes-three {
    grid-template-columns: auto;
  }
  .latest-actions {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
</style>