<template>
  <section>
    <div class="control">
      <h2>Evolução Semanal - Últimas {{ quantity }} Semanas</h2>
      <div class="control-buttons">
        <b-button icon-left="angle-up" @click="controlQuantity('+')" :disabled="quantity == 20" />
        <b-button icon-left="angle-down" @click="controlQuantity('-')" :disabled="quantity == 10" />
      </div>
    </div>
    <LineChart v-if="chartData" :chartData="chartData" />
  </section>
</template>

<script>
import LineChart from "./charts/LineChart.vue";
import { mapGetters } from "vuex";
import { weeklyEvolution } from "./weekly-evolution-generator/weeklyEvolutionGenerator";
export default {
  name: "WeeklyEvolution",
  components: {
    LineChart,
  },
  props: ["requests"],
  data() {
    return {
      quantity: 12,
    };
  },
  methods: {
    controlQuantity(operation){
      if(operation === '+'){
        this.quantity ++;
      } else {
        this.quantity = this.quantity - 1;
      }
    }
  },
  computed: {
    ...mapGetters(["opportunity", "contract"]),
    evolution() {
      if (this.requests) {
        if (this.contract) {
          return weeklyEvolution.constructor(
            this.contract.dates[0],
            this.contract.dates[1],
            this.requests,
            this.quantity
          );
        } else if (this.opportunity.contracts) {
          return weeklyEvolution.constructor(
            this.opportunity.contracts[0].dates[0],
            this.opportunity.contracts[this.opportunity.contracts.length - 1]
              .dates[1],
            this.requests,
            this.quantity
          );
        } else return null;
      } else return null;
    },
    chartData() {
      if (this.evolution) {
        return {
          labels: this.evolution.labels,
          datasets: [
            {
              label: "Solicitações",
              data: this.evolution.data,
              fill: false,
              borderColor: "#00b991",
              backgroundColor: "#00b991",
              borderWidth: 2,
            },
          ],
        };
      } else return null;
    },
  },
};
</script>

<style scoped>
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
}
.control{
  display: flex;
}
h2 {
  font-size: 20px;
  color: #404040;
  font-weight: 700;
  margin-bottom: 2rem;
}
.control-buttons {
  display: grid;
  grid-template-columns: 1rem;
  grid-template-rows: 1rem 1rem;
  margin-left: auto;
  margin-top: .2rem;
}
.button {
  height: 0.5rem;
  border-color: transparent;
}
</style>