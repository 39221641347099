<template>
  <div id="info1" class="container">
    <div class="columns">
      <div
        class="column has-bg-img content-image-div"
        :style="{ backgroundImage: 'url(' + contentImageURL + ')' }"
      ></div>

      <div class="column text-content">
        <div class="quill-editor">
          <div class="ql-container ql-bubble">
            <div class="ql-editor" v-html="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";

export default {
  props: ["contentImageURL", "content"]
};
</script>

<style scoped>
#info1 {
  margin-top: 116px;
}
.has-bg-img {
  background: center center;
  background-size: cover;
}
.content-image-div {
  height: 500px;
}
@media (max-width: 768px) {
  .content-image-div {
    height: 300px;
  }
}
.quill-editor {
  border: 1px solid rgb(211, 211, 211);
}
@media (min-width: 769px) {
  .quill-editor {
    height: 500px;
  }
}
.text-content {
  padding: 0 0 0 0.75rem;
}
@media (max-width: 1023px) {
  .text-content {
    padding: 0 1.5rem 0 0.75rem;
  }
}
@media (max-width: 768px) {
  .text-content {
    padding: 0 1.5rem;
  }
}
.ql-editor {
  padding: 3rem;
}
</style>
