<template>
  <section>
    <div class="document-header">
      <img src="../../assets/img/report/capa.png" />
    </div>
    <div class="title-page">
      <div class="title-info">
        <h1>RELATÓRIO PARCIAL DE RESULTADOS</h1>
        <img src="../../assets/img/logo-empower-branco-verde.png" />
        <!-- <h2>
          Plano
          {{
            opportunity.contracts[0].plan === "tasting"
              ? "Degustação"
              : opportunity.contracts[0].plan === "3months"
              ? "3 meses"
              : opportunity.contracts[0].plan === "semiannual"
              ? "6 meses"
              : opportunity.contracts[0].plan === "9months"
              ? "9 meses"
              : opportunity.contracts[0].plan === "annual"
              ? "12 meses"
              : "-"
          }}
          {{
            `${moment(opportunity.contracts[0].dates[0]).format(
              "DD/MM/YY"
            )} - ${moment(opportunity.contracts[0].dates[1]).format(
              "DD/MM/YY"
            )}`
          }}
        </h2> -->
        <!-- <h3>
          Documento gerado em:
          {{
            `${moment(today).format("DD/MM/YY")}`
          }}
        </h3> -->
      </div>
      <div class="company">
        <h2>{{ opportunity.businessName.toUpperCase() }}</h2>
        <div class="dna">
          <div class="parceria">Parceria Intitucional</div>
          <div class="street"></div>
          <img src="../../assets/img/report/dna.png" />
        </div>
      </div>
    </div>
    <div class="date">Extraído em: {{ `${moment(today).format("DD/MM/YY")}` }} - Plano de 
          {{
            opportunity.contracts[0].plan === "tasting"
              ? "Degustação"
              : opportunity.contracts[0].plan === "3months"
              ? "3 meses"
              : opportunity.contracts[0].plan === "semiannual"
              ? "6 meses"
              : opportunity.contracts[0].plan === "9months"
              ? "9 meses"
              : opportunity.contracts[0].plan === "annual"
              ? "12 meses"
              : "-"
          }}
          ({{
            `${moment(opportunity.contracts[0].dates[0]).format(
              "DD/MM/YY"
            )} - ${moment(opportunity.contracts[0].dates[1]).format(
              "DD/MM/YY"
            )}`
          }})</div>
    <h2 class="title-page">Ações Solicitadas</h2>
    <div class="boxes">
      <general-requests :requests="requests" class="box2" />
      <distribution-by-state :requests="requests" class="box2" />
      <distribution-by-type :requests="requests" class="box2" />
    </div>
    <b-button @click="show">ok</b-button>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { momentMixin } from "../../mixins/moment";
import GeneralRequests from "../report-structure/GeneralRequests";
import DistributionByState from "../dashboard/requested-actions/DistributionByState";
import DistributionByType from "../dashboard/requested-actions/DistributionByType";

export default {
  name: "ReportStructure",
  mixins: [momentMixin],
  components: {
    GeneralRequests,
    DistributionByState,
    DistributionByType,
  },
  data() {
    return {
      today: new Date(),
    };
  },
  computed: {
    ...mapGetters(["opportunity", "requests"]),
    businessLogoURL() {
      try {
        return this.opportunity.page.businessLogoURL;
      } catch {
        return null;
      }
    },
  },
  methods: {
    show() {
      console.log(this.opportunity);
    },
  },
};
</script>

<style scoped>
.document-header {
  background-color: #fdfdfd;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
}
.document-header img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.title-info {
  display: flex;
  background-color: black;
  color: rgb(2, 248, 199);
  height: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 2rem;
}
.title-info h1 {
  font-size: 1.6rem;
  font-weight: 800;
}
.title-info img {
  height: 1.5rem;
  margin-left: auto;
  margin-top: 0.8rem;
}
.title-page h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 4rem;
  color: black;
}
.boxes {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 1rem;
  gap: 0.5rem;
}
.box2 {
  transform: scale(0.7);
  transform-origin: 0 0;
  width: 35rem;
}

.company {
  display: flex;
}
.dna {
  display: grid;
  grid-template-columns: 6rem 1px 5rem;
  gap: 1rem;
  margin-left: auto;
  margin-right: 3rem;
  margin-top: 1rem;
  color: black;
}

.dna img {
  display: block;
  height: 40px;
  margin-top: 3px;
}

.parceria {
  text-align: right;
  font-weight: bold;
}

.street {
  width: 1px;
  height: 2rem;
  background-color: black;
  margin-top: auto;
  margin-bottom: auto;
}

.date{
  color: black;
  font-size: .8rem;
  margin-left: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>