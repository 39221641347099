<template>
  <section>
    <h2>Universidades Impactadas</h2>
    <table>
      <thead>
        <tr>
          <th><span>Ranking</span></th>
          <th style="width: 24rem"><span> </span></th>
          <th><span>Qtd.</span></th>
        </tr>
      </thead>
      <tr v-for="(university, i) in requestsCut" :key="i">
        <td>
          <span>{{ i + 1 }}º</span>
        </td>
        <td>
          <span>{{ university.university }}</span>
        </td>
        <td>
          <span>{{ university.occurrence }}</span>
        </td>
      </tr>
      <tr v-if="requestData.length < 1 || requestData === null">
        <td colspan="3"><span>Não há Universidades Impactadas</span></td>
      </tr>
      <tr v-if="requestData.length > limite" @click="verMais = !verMais">
        <td colspan="3">
          <span class="vermais">{{ verMais ? "ver mais" : "ver menos" }}</span>
        </td>
      </tr>
      <tr>
        <th colspan="2"><span>Total</span></th>
        <th>
          <span>{{ requestData.length || 0 }}</span>
        </th>
      </tr>
    </table>
  </section>
</template>

<script>
import { filter } from "./requested-actions-filter/requested-actions-filter";
export default {
  name: "ImpactedUniversities",
  props: ["requests"],
  data() {
    return {
      verMais: true,
      limite: 15,
    };
  },
  computed: {
    requestData() {
      if (this.requests) {
        return filter.impactedUniversities(this.requests);
      } else {
        return null;
      }
    },
    requestsCut() {
      if (this.requestData.length > this.limite) {
        if (this.verMais) {
          return this.requestData.slice(0, this.limite);
        } else {
          return this.requestData;
        }
      } else {
        return this.requestData;
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 20px;
  color: #404040;
  font-weight: 700;
}
section {
  background-color: #fdfdfd;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  display: block;
}
table {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
th {
  background-color: #ededed;
  height: 2rem;
}
tr {
  border-bottom: #f0f0f0 solid 1px;
  height: 2rem;
}
td:first-child,
th:last-child,
td:last-child {
  text-align: center;
}
th:first-child span {
  margin-left: 0.5rem;
}
th span,
td span {
  margin-top: 0.2rem;
  display: block;
}
.vermais {
  text-decoration: underline;
  cursor: pointer;
}
</style>