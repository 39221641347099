<template>
  <section class="feedback-modal">
    <b-button
      @click="$parent.$parent.acceptActionModalActive = false"
      class="closeModal"
    >
      <b-icon icon="times" size="is-small"></b-icon>
    </b-button>
    <div class="header" v-if="request.organization">
      ACEITAR AÇÃO - {{ request.organization.name }}
    </div>
    <div class="header" v-else-if="request.professor">
      ACEITAR AÇÃO - Prof. {{ request.professor.name }}
    </div>
    <div class="form">
      <b-field label="Tema definido para a Ação">
        <b-input v-model="title" :disabled="titleND"></b-input>
      </b-field>
      <b-field>
        <b-checkbox class="n-d" v-model="titleND">N/D</b-checkbox>
      </b-field>
      <div>
        <span class="label">Data definida para a Ação</span>
        <div class="date-picker">
          <b-field>
            <span class="button is-static">Confirmada</span>
            <b-datepicker
              placeholder="Clique para escolher"
              v-model="dateAgreed"
              :disabled="dateAgreedND"
              :min-date="minDate"
              :max-date="maxDate"
            />
          </b-field>
          <b-field>
            <b-timepicker
              placeholder="Início"
              icon="clock"
              v-model="timeStart"
              :disabled="dateAgreedND"
            />
            <b-timepicker
              placeholder="Fim"
              icon="clock"
              v-model="timeEnd"
              :disabled="dateAgreedND"
            />
          </b-field>
        </div>
      </div>
      <b-field>
        <b-checkbox class="n-d" v-model="dateAgreedND">N/D</b-checkbox>
      </b-field>

      <b-field label="Palestrante(s) definido(s) para a Ação">
        <b-input v-model="speaker" :disabled="speakerND"></b-input>
      </b-field>
      <b-field>
        <b-checkbox class="n-d" v-model="speakerND">N/D</b-checkbox>
      </b-field>

      <b-field label="Celular(es) do(s) Palestrante(s)">
        <b-input
          v-model="speakerCellphone"
          :disabled="speakerCellphoneND"
        ></b-input>
      </b-field>
      <b-field>
        <b-checkbox class="n-d" v-model="speakerCellphoneND">N/D</b-checkbox>
      </b-field>

      <b-field label="E-mail(s) do(s) Palestrante(s)">
        <b-input v-model="speakerEmail" :disabled="speakerEmailND"></b-input>
      </b-field>
      <b-field>
        <b-checkbox class="n-d" v-model="speakerEmailND">N/D</b-checkbox>
      </b-field>
    </div>
    <div style="width: fit-content; margin-right: auto; margin-left: auto">
      <b-button
        class="feedback aceitar"
        icon-left="check"
        rounded
        @click="acceptRequest"
        >Aceitar Ação</b-button
      >
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AcceptAction",
  props: ["request"],
  data() {
    const today = new Date()

    return {
      title: "",
      dateAgreed: null,
      timeStart: null,
      timeEnd: null,
      speaker: "",
      speakerCellphone: "",
      speakerEmail: "",

      titleND: false,
      dateAgreedND: false,
      speakerND: false,
      speakerCellphoneND: false,
      speakerEmailND: false,

      minDate: new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
      maxDate: new Date(today.getFullYear() + 3, today.getMonth(), today.getDate()),
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    acceptRequest() {
      if (this.validateInput().length > 0) {
        this.alertValidation(this.validateInput());
      } else {
        this.$store
          .dispatch("acceptRequest", this.formatedData())
          .then((res) => {
            this.$parent.$parent.updateSentModalActive = true;
            this.$parent.$parent.acceptActionModalActive = false;
            return res;
          })
          .catch((e) => {
            this.$buefy.dialog.alert(
              "Um erro ocorreu, tente novamente mais tarde."
            );
            return e;
          });
      }
    },

    validateInput() {
      let pendencies = [];
      if (this.title === "" && this.titleND == false) {
        pendencies.push("Tema definido para a Ação");
      }
      if (this.dateAgreed === null && this.dateAgreedND == false) {
        pendencies.push("Data definida para a Ação");
      }
      if (this.timeStart === null && this.dateAgreedND == false) {
        pendencies.push("Horário de início definido para a Ação");
      }
      if (this.timeEnd === null && this.dateAgreedND == false) {
        pendencies.push("Horário de finalização definido para a Ação");
      }
      if (this.speaker === "" && this.speakerND == false) {
        pendencies.push("Palestrante(s) definido(s) para a Ação");
      }
      if (this.speakerCellphone == "" && this.speakerCellphoneND == false) {
        pendencies.push("Celular(es) do(s) Palestrante(s)");
      }
      if (this.speakerEmail == "" && this.speakerEmailND == false) {
        pendencies.push("E-mail(s) do(s) Palestrante(s)");
      }
      return pendencies;
    },
    alertValidation(pendencies) {
      let pendenciesFormated = "";
      for (let i = 0; i < pendencies.length; i++) {
        pendenciesFormated += "<li>- " + pendencies[i] + "</li>";
      }
      this.$buefy.dialog.alert({
        title: "Entradas inválidas",
        message:
          "<b>Preencha as seguintes entradas corretamente:</b> <br> <ul>" +
          pendenciesFormated +
          "</ul>",
        type: "is-danger",
      });
    },
    formatedData() {
      var data = {
        token: this.token,
        requestId: this.request._id,
        title: "",
        dateOptions: {
          agreed: {
            start: null,
            end: null,
          },
        },
        speaker: "",
        speakerCellphone: "",
        speakerEmail: "" 
      };
      if (this.titleND) {
        this.title = "";
      } else {
        data.title = this.title;
      }
      if (this.dateAgreedND) {
        this.dateAgreed = null;
        this.timeStart = null;
        this.timeEnd = null;
      } else {
        let start = this.dateAgreed;
        start.setHours(this.timeStart.getHours());
        start.setMinutes(this.timeStart.getMinutes());
        let end = this.dateAgreed;
        end.setHours(this.timeEnd.getHours());
        end.setMinutes(this.timeEnd.getMinutes());
        data.dateOptions = {
          agreed: {
            start: start,
            end: end,
          },
        };
      }
      if (this.speakerND) {
        data.speaker = "";
      } else {
        data.speaker = this.speaker;
      }
      if (this.speakerCellphoneND) {
        data.speakerCellphone = "";
      } else {
        data.speakerCellphone = this.speakerCellphone;
      }
      if (this.speakerEmailND) {
        data.speakerEmail = "";
      } else {
        data.speakerEmail = this.speakerEmail;
      }
      return data;
    },
  },
  mounted(){
    this.title= this.request.eventInfo.title || "";
    if(this.request.eventInfo.dateOptions.agreed.start){
      this.dateAgreed= new Date(this.request.eventInfo.dateOptions.agreed.start)
    } else {
      this.dateAgreed = null;
    }
    if(this.request.eventInfo.dateOptions.agreed.start){
      this.timeStart= new Date(this.request.eventInfo.dateOptions.agreed.start)
    } else {
      this.timeStart = null;
    }
    if(this.request.eventInfo.dateOptions.agreed.end){
      this.timeEnd= new Date(this.request.eventInfo.dateOptions.agreed.end)
    } else {
      this.timeEnd = null
    }
    this.speaker= this.request.eventInfo.speaker || "";
    this.speakerCellphone= this.request.eventInfo.speakerCellphone || "";
    this.speakerEmail= this.request.eventInfo.speakerEmail || "";
  }
};
</script>

<style scoped>
.header {
  padding-bottom: 1rem;
  border-bottom: solid 1px #dbdbdb;
  font-weight: bold;
}
.form {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: auto 4rem;
  gap: 1rem;
}
.n-d {
  margin-top: 2.4rem;
}

.field:not(:last-child) {
  margin-bottom: 0 !important;
}

.closeModal {
  border: none;
  display: block;
  margin-left: auto;
  margin-right: -0.2rem;
  margin-top: 0;
}

.closeModal:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
}

.aceitar {
  background-color: #33c7a7;
  color: white;
  font-weight: bold;
  margin-top: 2rem;
}

.date-picker {
  display: flex;
  gap: 1rem;
}
</style>