<template>
  <div
    id="logo-div"
    class="box has-bg-img"
    :style="{ backgroundImage: 'url(' + businessLogoURL + ')' }"
  ></div>
</template>

<script>
export default {
  props: ["businessLogoURL"]
};
</script>

<style scoped>
.has-bg-img {
  background: center center;
  background-size: cover;
}
#logo-div {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 350px;
  top: 216px;
}
@media (max-width: 769px) {
  #logo-div {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}
</style>
