<template>
  <b-modal :active="$parent.contactModalIsActive" :width="500" :can-cancel="false">
      <div class="contact" v-if="opportunity.empowerResponsible">
        <b-button
          @click="$parent.contactModalIsActive = false"
          class="closeModal"
        >
          <b-icon icon="times" size="is-small"></b-icon>
        </b-button>
        <div class="contact-content">
          <h2>Contato</h2>
          <br />
          <span>
            Responsável pelo Projeto: {{ opportunity.empowerResponsible.name }}
            <br />
            E-mail: {{ contactEmpower }} <br />
            <br />
            <span v-if="opportunity.empowerResponsible.cellphone">Whatsapp: {{ opportunity.empowerResponsible.cellphone }}</span>
          </span>
        </div>
      </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Contact",
  data() {
    return {
      contactEmpower: "relacionamento@empower.vision",
    };
  },
  computed: {
    ...mapGetters(["opportunity"]),
  },
};
</script>

<style scoped>
.contact {
  background-color: white;
  border: solid 1px #959595;
  padding-bottom: 2rem;
}
.closeModal {
  border: none;
  display: block;
  margin-left: auto;
  margin-right: 0.2rem;
  margin-top: 0;
}

.closeModal:hover {
  transform: scale(1.1);
  transition: 0.1s ease-in-out;
}
h2 {
  font-weight: bold;
}
.contact-content {
  text-align: center;
}
</style>