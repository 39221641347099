import $http from "../axios";
import { request } from "../mixins/request";

const state = {
  requests: []
};

const getters = {
  requests(state) {
    return state.requests;
  },
  requestsOrderedNewerToOlder(state) {
    return state.requests.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }
};

const actions = {
  getRequests({ commit }, token) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/requests?token=${token}`)
        .then(res => {
          commit("SET_REQUESTS", res.data.payload.requests);
          commit("SET_OPPORTUNITY", res.data.payload.opportunity);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  favoriteRequest({ commit }, data) {
    commit("SET_REQUEST_FAVORITE", data)
    return new Promise((resolve, reject) => {
      $http
        .post(`favorite-request`, {
          token: data.token,
          requestId: data.requestId,
          isFavorite: data.isFavorite
        })
        .then(res => {
          commit("SET_REQUEST_FAVORITE", data);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  acceptRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      $http
        .post(`accept-request`, {
          token: data.token,
          requestId: data.requestId,
          title: data.title,
          agreed: data.dateOptions.agreed,
          speaker: data.speaker,
          speakerCellphone: data.speakerCellphone,
          speakerEmail: data.speakerEmail
        })
        .then(res => {
          commit("SET_ACCEPT_REQUEST", data);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    })
  },

  denyRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      $http
        .post(`deny-request`, {
          token: data.token,
          requestId: data.requestId,
          noAvailabilityReason: data.noAvailabilityReason,
          noAvailabilityComment: data.noAvailabilityComment
        })
        .then(res => {
          commit("SET_DENY_REQUEST", data);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    })
  },

  addComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      $http
        .post(`add-comment`, {
          token: data.token,
          requestId: data.requestId,
          comment: data.comment.comment
        })
        .then(res => {
          commit("SET_COMMENT", data);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    })
  }
};

const mutations = {
  SET_REQUESTS(state, requests) {
    requests.map(r => {
      r.visibleStatus = request.methods.requestStatusForBusiness(
        r.requestStatus
      );
      return r;
    });
    state.requests = requests;
  },
  SET_REQUEST_FAVORITE(state, data) {
    state.requests = state.requests.map(r => {
      if (r._id === data.requestId) {
        r.isFavorite = data.isFavorite;
      }
      return r;
    });
  },
  SET_ACCEPT_REQUEST(state, data) {
    state.requests = state.requests.map(r => {
      if (r._id === data.requestId) {
        r.eventInfo.title = data.title;
        r.eventInfo.dateOptions.agreed = data.dateOptions.agreed;
        r.eventInfo.speaker = data.speaker;
        r.eventInfo.speakerCellphone = data.speakerCellphone;
        r.eventInfo.speakerEmail = data.speakerEmail;
        r.confirmedViaBusiness = true;
      }
      return r;
    })
  },
  SET_DENY_REQUEST(state, data) {
    state.requests = state.requests.map(r => {
      if (r._id === data.requestId) {
        r.noAvailabilityReason = data.noAvailabilityReason;
        r.noAvailabilityComment = data.noAvailabilityComment;
        r.noAvailabilityViaBusiness = true;
      }
    })
  },
  SET_COMMENT(state, data) {
    state.requests = state.requests.map(r => {
      if (r._id === data.requestId) {
        r.businessComments.push(data.comment)
      }
    })
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
